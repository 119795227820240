import React from 'react';

interface IWarningMessageProps {
  message: string;
}

export default function WarningMessage(props: IWarningMessageProps) {
  return (
    <div className="mt-2 border-l-2 border-yellow-400 bg-yellow-50 py-1 px-2">
      <p className="text-sm text-yellow-700 break-words">{props.message}</p>
    </div>
  );
}

import React, { useContext } from 'react';
import { HandThumbUpIcon as HandThumbUpIconSolid } from '@heroicons/react/24/solid';
import { RocketLaunchIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { type IAuthContext, AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { getConfig } from '../config/config-helper';
import { useAppSelector } from '../hooks';
import { sendMetrics } from '../utilities/analytics';
import { type ISolution } from '../reducers/solutionsSlice';
import { selectUser } from '../reducers/accountSlice';

interface ISocialFeaturesProps {
  solution: ISolution;
  releaseStatus: string;
  refreshSolution: () => void;
}

export default function SocialFeatures({ solution, releaseStatus, refreshSolution }: ISocialFeaturesProps) {
  const { token } = useContext<IAuthContext>(AuthContext);
  const dataopsliveBaseUrl = getConfig().dataopsliveBaseUrl;
  const currentUser = useAppSelector(selectUser);
  const staredByUser = currentUser?.starredProjects.nodes.some((project) => project.id === solution?.guid);

  if (releaseStatus !== 'Published') {
    return <></>;
  }

  async function starProject() {
    if (solution === undefined) {
      return;
    }

    if (staredByUser !== undefined) {
      const id: string = solution.id;
      const urlBase = `${dataopsliveBaseUrl}/api/v4/projects/${id}`;
      const url = `${urlBase}/${staredByUser ? 'unstar' : 'star'}`;
      await axios.post(url, '', {
        headers: { authorization: `Bearer ${token}` },
      });

      const eventName = staredByUser
        ? 'dataops-solution-homepage-solution-unstarred'
        : 'dataops-solution-homepage-solution-starred';

      const analyticsData = {
        event_name: eventName,
        event_source: 'frostbyte-deployment-portal',
        properties: {
          username: currentUser?.username,
          project_name: solution.fullPath,
          dataops_project_id: solution.id,
        },
      };
      sendMetrics(analyticsData);

      refreshSolution();
    }
  }

  return (
    <>
      <div
        className="p-2 flex items-center hover:bg-slate-200 rounded-md"
        style={{ cursor: 'pointer' }}
        onClick={starProject}
      >
        {staredByUser === true ? (
          <HandThumbUpIconSolid className="w-5 h-5 text-dataops-light-blue" />
        ) : (
          <HandThumbUpIcon className="w-5 h-5 text-dataops-light-blue" />
        )}
        <div className="text-xs text-gray-800 ml-[1px]">{solution?.starCount ?? '0'}</div>
      </div>

      <div className="ml-1 h-6 flex flex-row items-center rounded-md px-2.5 py-0.5 bg-white border border-dataops-dark-blue/70">
        <RocketLaunchIcon className="w-5 h-5 text-dataops-dark-blue/70 mr-2" />
        <span className="inline-flex  text-sm font-medium text-dataops-dark-blue/70 ">
          Deployed {solution?.environments?.length ?? '0'}
        </span>
      </div>
    </>
  );
}

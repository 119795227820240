import React from 'react';

export interface ITagSectionProps {
  tags: string[];
}

const TagSection: React.FC<ITagSectionProps> = (props) => {
  if (props.tags === undefined || (props.tags.length === 1 && props.tags[0] === undefined)) {
    return <></>;
  }

  return (
    <div className="text-sm flex">
      <div className="flex flex-wrap">
        {props.tags.map((tag) => (
          <span
            key={tag}
            className="inline-flex items-center rounded bg-[#c5e2f3] text-dataops-dark-blue px-2 py-0.5 text-xs font-medium mr-1 mb-1"
          >
            {tag}
          </span>
        ))}
        {props.tags.length === 0 && (
          <span className="inline-flex items-center rounded bg-gray-100 text-gray-400 px-2 py-0.5 text-xs font-medium mr-1 mb-1">
            None
          </span>
        )}
      </div>
    </div>
  );
};

export default TagSection;

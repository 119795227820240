import React, { type ReactNode } from 'react';

interface ISetupSectionProps {
  title: string;
  description: string;
  children: ReactNode;
}

export default function SetupSection({ title, description, children }: ISetupSectionProps) {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10 first:pt-5">
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900">{title}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600 whitespace-pre-line">{description}</p>
      </div>
      <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-6 sm:p-8">{children}</div>
      </div>
    </div>
  );
}

import React, { useState, Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import Button from './Button';
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import CloseButton from './CloseButton';

interface MarkdownPreviewProps {
  markdown: string;
}

export default function MarkdownPreview(props: MarkdownPreviewProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (isExpanded) {
    return (
      <Transition.Root show={isExpanded} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          onClose={() => {
            setIsExpanded(false);
          }}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-gray-50 text-left shadow-xl transition-all sm:my-20 sm:w-full sm:max-w-[60rem]">
                  <CloseButton setOpen={setIsExpanded} className="absolute top-4 right-4" />
                  <div className="border-[1px] rounded-md overflow-hidden">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} className="markdown p-8">
                      {props.markdown}
                    </ReactMarkdown>
                    <Button
                      onClick={() => {
                        setIsExpanded(false);
                      }}
                      className="w-full h-[2rem] bg-dataops-light-blue hover:bg-hover-light-blue text-white"
                      data-testid="hide-preview-button"
                    >
                      Hide Preview
                    </Button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  return (
    <div className="max-h-[10rem] overflow-hidden border-[1px] rounded-md relative">
      <Button
        onClick={() => {
          setIsExpanded(true);
        }}
        className="w-full"
        intent="light"
        size="large"
        data-testid="listing-preview-button"
      >
        <DocumentMagnifyingGlassIcon className="w-6 h-6 ml-1 mr-2" />
        Learn more
      </Button>
    </div>
  );
}

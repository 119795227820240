import { Dialog, DialogPanel, Switch, Transition, TransitionChild } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import React, { Fragment, useContext, useState } from 'react';
import { ToWords } from 'to-words';
import { useAppDispatch, useAppSelector } from '../hooks';
import useStoredAccounts from '../hooks/useStoredAccounts';
import { type IAuthContext, AuthContext } from 'react-oauth2-code-pkce';
import { opportunityIdLinkConverter, opportunityIdValidation } from '../utilities/validation';
import {
  selectHolEnabled,
  selectPersistedSnowflakeAccounts,
  selectStoreAccounts,
  selectVerticalThemesEnabled,
  setStoreAccounts,
} from '../reducers/projectsSlice';
import { classNames } from '../utilities/styleUtils';

import { getConfig } from '../config/config-helper';
import type { IDeployRequest, ISnowflakeDataShare } from '../interfaces/IDeployRequest';
import { sendMetrics } from '../utilities/analytics';
import Button from './Button';
import CheckBoxList from './CheckBoxList';
import ErrorMessages from './ErrorMessages';
import ExperimentalFeatureToggle from './ExperimentalFeatureToggle';
import FormField from './FormField';
import HolSetup from './HolSetup';
import InformationTooltip from './InformationTooltip';
import PersistedAccountSelector from './PersistedAccountSelector';
import SetupSection from './SetupSection';
import Spinner from './Spinner';
import VerticalThemeSelector from './VerticalThemeSelector';
import CloseButton from './CloseButton';
import { DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
import { selectUser } from '../reducers/accountSlice';
import { selectDeployments } from '../reducers/deploymentsSlice';

const progressMessages = [
  { text: '(1/3) We are configuring your solution instance, this may take a few seconds...', duration: 10 },
  { text: '(2/3) Checking for instance deployment...', duration: 20 },
  { text: '(3/3) Checking your instance has deployed and setup has started...', duration: 20 },
];

interface ISnowflakeDataShareIncluded extends ISnowflakeDataShare {
  include: boolean;
}

interface ISetupModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  cancelButtonRef: React.RefObject<HTMLButtonElement>;
  prefillProjectTitle: string;
  templateId: number;
  opportunityId: string;
  preInstanceSetupCheckList?: string[];
  preInstanceSetupMessage?: string;
  requiredDataShares: ISnowflakeDataShare[] | undefined;
  refreshProjects: () => void;
  setDeploymentsModelOpen: (open: boolean) => void;
  verticalThemes: string[] | undefined;
  customerNameSuffix?: string;
}

export default function SetupModal({
  open,
  setOpen,
  cancelButtonRef,
  prefillProjectTitle,
  templateId,
  preInstanceSetupCheckList,
  preInstanceSetupMessage,
  requiredDataShares,
  refreshProjects,
  setDeploymentsModelOpen,
  verticalThemes,
  customerNameSuffix,
}: ISetupModalProps) {
  const formDefaults = {
    projectTitle: getSolutionTitle(''),
    opportunityId: '',
    account: '',
    snowflakeAccountUsername: '',
    snowflakeAccountPassword: '',
    snowflakeAccountOtp: '',
    role: '',
    warehouse: '',
    customerName: '',
    customerLogoUrl: '',
  };
  const dispatch = useAppDispatch();
  const [projectTitle, setProjectTitle] = useState<string>(formDefaults.projectTitle);
  const [account, setAccount] = useState<string>(formDefaults.account);
  const [snowflakeAccountUsername, setUsername] = useState<string>(formDefaults.snowflakeAccountUsername);
  const [snowflakeAccountPassword, setPassword] = useState<string>(formDefaults.snowflakeAccountPassword);
  const [snowflakeAccountOtp, setOtp] = useState<string>(formDefaults.snowflakeAccountOtp);
  const [opportunityIdErrorMessage, setOpportunityIdErrorMessage] = useState<string>(''); // Opportunity ID validation error message
  const [customerName, setCustomerName] = useState<string>(formDefaults.customerName);
  const [opportunityId, setOpportunityId] = useState<string>(formDefaults.opportunityId);
  const [customerLogoUrl, setCustomerLogoUrl] = useState<string>(formDefaults.customerLogoUrl);
  const [customerLogoUrlError, setCustomerLogoUrlError] = useState<boolean>(true);
  const snowflakeAccounts = useAppSelector(selectPersistedSnowflakeAccounts);
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const [errors, setErrors] = useState<string[] | undefined>([]);
  const [showOtpField, setShowOtpField] = useState<boolean>(false);
  const [showProgressMessage, setShowProgressMessage] = useState<boolean>(false);
  const [progressMessageIndex, setProgressMessageIndex] = useState(0);
  const { token } = useContext<IAuthContext>(AuthContext);
  const projects = useAppSelector(selectDeployments);
  const { dataopsCatalogApiDeployEndpoint, dataopsliveBaseUrl } = getConfig();
  const username = useAppSelector(selectUser)?.username;
  const verticalThemesEnabled = useAppSelector(selectVerticalThemesEnabled);
  const [selectedVerticalTheme, setSelectedVerticalTheme] = useState<string>('default');
  const holEnabled = useAppSelector(selectHolEnabled);
  const [numberOfHolParticipants, setNumberOfHolParticipants] = useState<number>(2);
  const { addAccount } = useStoredAccounts();
  const [dataSharesIncluded, setDataSharesIncluded] = useState(initDataSharesIncluded(requiredDataShares));
  const [anyDataSharesExcluded, setAnyDataSharesExcluded] = useState<boolean>(false);
  const errorMsgDataShareTerms = 'data share terms and conditions';
  const errorMsgDuoPush = 'Duo Push is not enabled';
  const storeAccounts = useAppSelector(selectStoreAccounts);

  function handleAllItemsChecked(allItemsChecked: boolean): void {
    setCanSubmit(allItemsChecked);
  }

  function initDataSharesIncluded(
    requiredDataShares: ISnowflakeDataShare[] | undefined,
  ): ISnowflakeDataShareIncluded[] {
    if (requiredDataShares === undefined) {
      return [];
    }
    return requiredDataShares?.map((dataShare) => {
      return {
        create_database: dataShare.create_database,
        from_share: dataShare.from_share,
        grant_imported_privileges_to_roles: dataShare.grant_imported_privileges_to_roles,
        share_url: dataShare.share_url,
        include: true,
      };
    });
  }

  function checkIfAnyDataSharesAreExcluded(): boolean {
    return dataSharesIncluded.find((dataShare) => !dataShare.include) !== undefined;
  }

  function handleErrorResponse(error: any) {
    console.log('Error while submitting setup', error);
    if (error.response?.data?.reason !== undefined) {
      if (error.response.data?.reason.find((response: string) => response.includes(errorMsgDuoPush)) !== undefined) {
        setShowOtpField(true);
        return;
      } else if (
        error.response.data?.reason.find((response: string) => response.includes(errorMsgDataShareTerms)) !== undefined
      ) {
        setErrors(
          error.response.data?.reason.map((element: string, index: number) => {
            if (element.includes(errorMsgDataShareTerms)) {
              return element;
            } else {
              return (
                <a key={index} href={element} target="_blank" rel="noreferrer">
                  {element}
                </a>
              );
            }
          }),
        );
      } else {
        setErrors(error.response.data?.reason);
      }
      console.log('Failed to set up solution: ', {
        code: error.code,
        message: error.message,
        reason: error.response.data.reason,
      });
    } else {
      if (error?.message !== undefined) {
        setErrors(["We're sorry, something went wrong.", error.message]);
      } else {
        setErrors(["We're sorry, something went wrong. Please refresh the page and try again."]);
      }
    }
  }
  function resetForm() {
    setProjectTitle(formDefaults.projectTitle);
    setPassword(formDefaults.snowflakeAccountPassword);
    setOtp(formDefaults.snowflakeAccountOtp);
    setCustomerName(formDefaults.customerName);
    setOpportunityId(formDefaults.opportunityId);
    setCustomerLogoUrl(formDefaults.customerLogoUrl);
    setShowOtpField(false);
    setErrors([]);
    setShowProgressMessage(false);
  }

  function isCustomerNameValid(title: string): boolean {
    const regex = /^[a-zA-Z0-9]+[a-zA-Z0-9_-\s\.]*$/;
    return regex.test(title);
  }

  function isSolutionPrefixValid(prefix: string): boolean {
    const regex = /^[a-zA-Z0-9]+[a-zA-Z0-9_-]*$/;
    return regex.test(prefix);
  }

  function solutionTitleExists(title: string): boolean {
    for (let i = 0; i < projects.length; ++i) {
      if (projects[i].name === title) {
        return true;
      }
    }
    return false;
  }

  function getSolutionTitle(customerName: string): string {
    // If the template title is longer than 14 characters, use the initials of each word
    const templateTitle = prefillProjectTitle;
    let filteredTitle = `${customerName}_${templateTitle ?? ''}`;
    // If customer_name_suffix exists in listing.yml use that to generate solution prefix
    if (customerNameSuffix !== undefined && customerNameSuffix !== '') {
      filteredTitle = `${customerName}_${customerNameSuffix}`;
    }
    filteredTitle = filteredTitle.replaceAll(/[^a-zA-Z0-9_\-]/g, '').replaceAll('-', '');
    // If the first character is a number, replace it with its word equivalent.
    if (filteredTitle.charAt(0).match(/[0-9]/) !== null) {
      filteredTitle = filteredTitle.replace(
        filteredTitle.charAt(0),
        new ToWords().convert(parseInt(filteredTitle.charAt(0))),
      );
    }
    filteredTitle = filteredTitle.toUpperCase();
    return filteredTitle;
  }

  async function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (!isCustomerNameValid(customerName)) {
      setErrors([
        "The customer name can only contain letters, digits, '_', '.', dash, space. It must start with a letter or a digit.",
      ]);
      return;
    }

    if (!isSolutionPrefixValid(projectTitle)) {
      setErrors([
        "The solution prefix can only contain letters, digits, '_' and dash. It must start with a letter or a digit.",
      ]);
      return;
    }

    if (solutionTitleExists(projectTitle)) {
      setErrors(['Customer name already exists. Try another.']);
      return;
    }

    if (opportunityIdErrorMessage !== '') {
      setErrors(['Opportunity ID is invalid', opportunityIdErrorMessage]);
      return;
    }

    // Add account to persisted accounts if it's not persisted already
    const accountAlreadyPersisted =
      snowflakeAccounts.findIndex((a) => a.accountLocator === account && a.username === snowflakeAccountUsername) !==
      -1;
    if (!accountAlreadyPersisted && storeAccounts) {
      addAccount({ accountLocator: account, username: snowflakeAccountUsername });
    }

    const analyticsData = {
      event_name: 'dataops-solution-homepage-create-solution-button-click',
      event_source: 'frostbyte-deployment-portal',
      properties: {
        project_name: projectTitle,
        customer_name: customerName,
        snowflake_account: account,
        snowflake_username: snowflakeAccountUsername,
      },
    };
    sendMetrics(analyticsData);

    let requestBody: IDeployRequest = {
      template_id: templateId,
      opportunity_id: opportunityId,
      parent_namespace_id: 11996,
      platform_url: `${dataopsliveBaseUrl}/`,
      project_name: projectTitle,
      project_pipeline_file: 'full-ci.yml',
      snowflake_account: account,
      snowflake_username: snowflakeAccountUsername,
      snowflake_password: snowflakeAccountPassword,
      snowflake_otp: undefined,
      customer_logo_url:
        customerLogoUrl === ''
          ? 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Snowflake_Logo.svg/184px-Snowflake_Logo.svg.png'
          : customerLogoUrl,
      customer_name: customerName,
      vertical_theme: selectedVerticalTheme,
      hands_on_labs_total_participants: holEnabled ? numberOfHolParticipants : 0,
    };

    if (showOtpField) {
      requestBody = { ...requestBody, snowflake_otp: snowflakeAccountOtp };
    }
    if (requiredDataShares !== undefined) {
      const includedDataShares = dataSharesIncluded
        .filter((dataShare) => dataShare.include)
        .map((dataShare) => {
          return {
            create_database: dataShare.create_database,
            from_share: dataShare.from_share,
            grant_imported_privileges_to_roles: dataShare.grant_imported_privileges_to_roles,
            share_url: dataShare.share_url,
          };
        });
      requestBody = { ...requestBody, snowflake_data_shares: includedDataShares };
    }
    setCanSubmit(false);
    setErrors([]);
    setShowProgressMessage(true);
    updateProgressMessage(0);
    try {
      await axios.post(dataopsCatalogApiDeployEndpoint, requestBody, {
        headers: { authorization: `Bearer ${token}` },
      });
      updateProgressMessage(1);
      const glProject = await getProject(`snowflake/instances/${username ?? ''}/${projectTitle}`);
      updateProgressMessage(2);
      const glPipelines = await getPipelines(glProject);
      await getPipelineJobProgress(glProject, glPipelines[0]);
      setOpen(false);
      setDeploymentsModelOpen(true);
      resetForm();
      refreshProjects();
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setCanSubmit(true);
      setShowProgressMessage(false);
    }
  }
  async function getProject(projectId: string) {
    const urlEncodedProjectId = encodeURIComponent(projectId);
    const url = `${dataopsliveBaseUrl}/api/v4/projects/${urlEncodedProjectId}`;
    for (let i = 0; i < 30; ++i) {
      try {
        const response = await axios.get(url, {
          headers: { authorization: `Bearer ${token}` },
        });
        return response.data;
      } catch (error) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
    throw new Error(
      `Project not found. Your instance was not setup. Please contact support to investigate and supply your project name ${projectId}`,
    );
  }
  /**
   *
   * @param gitlabProject REST response for a Project from GitLab API
   * @returns REST response for a Pipeline from GitLab API
   */
  async function getPipelines(gitlabProject: { id: number; web_url: string }) {
    const url = `${dataopsliveBaseUrl}/api/v4/projects/${gitlabProject.id}/pipelines`;
    for (let i = 0; i < 30; ++i) {
      try {
        const response = await axios.get(url, {
          headers: { authorization: `Bearer ${token}` },
        });
        if (response.data.length > 0) {
          return response.data;
        }
        await new Promise((resolve) => setTimeout(resolve, 5000));
      } catch (error) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    }
    throw new Error(
      `Pipeline not found. Your instance was setup but the pipeline failed to start. Head over to ${gitlabProject.web_url}/pipelines to run the pipeline manually.`,
    );
  }
  /**
   * Polls the GitLab API for the pipeline job progress
   * @param gitlabPipeline REST response for a Pipeline from GitLab API
   * @returns REST response for a Pipeline from GitLab API
   * @throws Error if the pipeline job fails
   * @throws Error if the pipeline job does not complete within 10 minutes
   */
  async function getPipelineJobProgress(gitlabProject: { id: number; web_url: string }, glPipeline: { id: number }) {
    const url = `${dataopsliveBaseUrl}/api/v4/projects/${gitlabProject.id}/pipelines/${glPipeline.id}/jobs`;
    for (let i = 0; i < 30; ++i) {
      try {
        const response = await axios.get(url, {
          headers: { authorization: `Bearer ${token}` },
        });
        // For each job, check if it is running or failed
        for (let j = 0; j < response.data.length; ++j) {
          if (response.data[j].status === 'running') {
            return;
          }
        }
        await new Promise((resolve) => setTimeout(resolve, 2000));
      } catch (error) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
    throw new Error(
      `Pipeline not found. Your instance was setup but the pipeline failed to start. Head over to ${gitlabProject.web_url}/pipelines to run the pipeline manually.`,
    );
  }

  function updateProgressMessage(index: number) {
    setProgressMessageIndex(index);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30 " initialFocus={cancelButtonRef} onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-gray-50 text-left shadow-xl transition-all sm:my-24 sm:w-full sm:max-w-5xl">
                <form onSubmit={onSubmit}>
                  <div className="flex justify-end pt-6 pr-6 w-full">
                    <CloseButton setOpen={setOpen} className="" />
                  </div>
                  <div className="space-y-10 divide-y divide-gray-900/10 p-6">
                    {preInstanceSetupMessage !== undefined && preInstanceSetupMessage !== '' && (
                      <SetupSection
                        title="Prerequisites"
                        description="Follow these instructions to prepare your Snowflake account for this deployment."
                      >
                        <div className="w-full max-w-2xl">
                          <div
                            id="pre-instance-message-container"
                            className="text-sm text-left mb-4"
                            dangerouslySetInnerHTML={{ __html: preInstanceSetupMessage }}
                          ></div>
                        </div>
                      </SetupSection>
                    )}
                    {preInstanceSetupCheckList !== undefined && preInstanceSetupCheckList.length > 0 && (
                      <SetupSection
                        title="To do checklist:"
                        description="Make sure you have checked these actions prior to submission."
                      >
                        <CheckBoxList
                          list={preInstanceSetupCheckList ?? []}
                          handleAllItemsChecked={handleAllItemsChecked}
                        />
                      </SetupSection>
                    )}
                    {requiredDataShares !== undefined &&
                      requiredDataShares.length > 0 &&
                      requiredDataShares.find((share: ISnowflakeDataShare) => typeof share.from_share === 'string') ===
                        undefined && (
                        <SetupSection
                          title="Data Shares"
                          description="Control which data shares are automatically added to your account."
                        >
                          <ul role="list" className="divide-y divide-gray-100">
                            {dataSharesIncluded.map((dataShare, index) => {
                              return (
                                <li key={`${dataShare.create_database.toLowerCase()}-${index}`} className="py-4">
                                  <div className="flex items-center gap-x-3">
                                    <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-gray-900">
                                      <span className="text-xs text-gray-500">Database name: </span>
                                      {dataShare.create_database}
                                    </h3>
                                    <span className="flex-none text-xs text-gray-500">
                                      <ExperimentalFeatureToggle
                                        label="Include"
                                        checked={dataShare.include}
                                        onChange={() => {
                                          const newDataSharesIncluded = [...dataSharesIncluded];
                                          newDataSharesIncluded[index].include = !dataShare.include;
                                          setDataSharesIncluded(newDataSharesIncluded);
                                          setAnyDataSharesExcluded(checkIfAnyDataSharesAreExcluded());
                                        }}
                                      />
                                    </span>
                                  </div>
                                  {dataShare.share_url !== '' && (
                                    <p className="mt-3 truncate text-sm text-blue-500">
                                      <a
                                        href={dataShare.share_url}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="flex items-center"
                                      >
                                        View share in Snowflake <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
                                      </a>
                                    </p>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                          <Transition
                            show={anyDataSharesExcluded}
                            enter="transition duration-[300ms]"
                            enterFrom="transform opacity-0 scale-[0.9]"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition duration-[300ms] ease-in-out"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-[0.9]"
                          >
                            <div className="rounded-md bg-yellow-50 p-4">
                              <div className="flex">
                                <div className="flex-shrink-0">
                                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                  <h3 className="text-sm font-medium text-yellow-800">
                                    Caution when excluding data shares
                                  </h3>
                                  <div className="mt-2 text-sm text-yellow-700">
                                    <p>Not importing the data will cause the setup to fail.</p>
                                    <p className="mt-2">It&apos;s OK to have data shares already in your account.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Transition>
                        </SetupSection>
                      )}
                    <SetupSection
                      title="Solution Instance"
                      description={`This information will be used to build out your custom solution instance for a customer.
                      You can also keep the customer name generic to re-use the instance for multiple customers.`}
                    >
                      <div className="w-full max-w-2xl space-y-6">
                        <FormField
                          className="w-2/3"
                          label="Customer name"
                          value={customerName}
                          disabled={!canSubmit}
                          required={true}
                          placeholder='e.g. "Acme Inc."'
                          onChange={(e) => {
                            setCustomerName(e.target.value);
                            setProjectTitle(getSolutionTitle(e.target.value));
                            solutionTitleExists(getSolutionTitle(e.target.value));
                          }}
                          testId="customer-name-input"
                        />
                        <FormField
                          className="w-2/3"
                          label="Opportunity ID (optional)"
                          value={opportunityId}
                          disabled={!canSubmit}
                          required={false}
                          informationTooltip="Paste a link to an Opportunity from Salesforce or enter the ID. Help us link progress with a customer."
                          labelExtra={
                            <a
                              href="https://snowforce.lightning.force.com/lightning/"
                              target="_blank"
                              rel="noreferrer"
                              className="text-sm hover:underline text-sky-600 ml-2 flex items-center"
                            >
                              Launch Salesforce
                              <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
                            </a>
                          }
                          placeholder='e.g. "aI6Do000000PDmhKAG"'
                          onChange={(e) => {
                            const output = opportunityIdLinkConverter(e.target.value);
                            setOpportunityIdErrorMessage(opportunityIdValidation(output));
                            setOpportunityId(output);
                          }}
                          testId="opportunity-id-input"
                        >
                          <Transition
                            show={opportunityIdErrorMessage !== ''}
                            enter="transition duration-[300ms]"
                            enterFrom="transform opacity-0 scale-[0.9]"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition duration-[300ms] ease-in-out"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-[0.9]"
                          >
                            <div className="rounded-md bg-yellow-50 p-4">
                              <div className="flex">
                                <div className="flex-shrink-0">
                                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                  <h3 className="text-sm font-medium text-yellow-800">Validation warning!</h3>
                                  <div className="mt-2 text-sm text-yellow-700">
                                    <p>{opportunityIdErrorMessage}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Transition>
                        </FormField>

                        <FormField
                          label="Customer logo URL (optional)"
                          informationTooltip="A URL to a customer logo accessible online (ideally a small square image). Head to your search engine and find an image URL"
                          value={customerLogoUrl}
                          disabled={!canSubmit}
                          placeholder='e.g. "https://acme.com/logo.png"'
                          onChange={(e) => {
                            setCustomerLogoUrl(e.target.value);
                            setCustomerLogoUrlError(false);
                          }}
                          testId="customer-logo-url-input"
                        >
                          <div>
                            {customerLogoUrlError ? (
                              <div className="flex items-center">
                                <div className="h-10 w-10 mr-2 border rounded-md border-gray-300"></div>
                                <div className="text-xs">Preview</div>
                              </div>
                            ) : (
                              <div className="flex flex-wrap items-center">
                                <img
                                  src={customerLogoUrl}
                                  onError={(e) => {
                                    setCustomerLogoUrlError(true);
                                  }}
                                  className="h-10 max-w-full mr-2 border rounded-md border-gray-300"
                                />
                                <div className="text-xs text-lime-600">Preview - Great work!</div>
                              </div>
                            )}
                          </div>
                        </FormField>
                        <div className="flex items-end">
                          <FormField
                            className="w-2/3 font-mono font-medium text-base"
                            label="Solution prefix"
                            value={projectTitle.toUpperCase()}
                            disabled={!canSubmit}
                            required={true}
                            informationTooltip="Optionally customize the solution prefix. The prefix will be used when naming Snowflake objects. WARNING: Ensure the prefix is unique and does not conflict with other solution prefixes
."
                            placeholder={projectTitle.toUpperCase()}
                            onChange={(e) => {
                              if (e.target.value !== '') {
                                setProjectTitle(e.target.value);
                                solutionTitleExists(e.target.value);
                              } else {
                                setProjectTitle(e.target.value);
                                solutionTitleExists(e.target.value);
                              }
                            }}
                          />
                        </div>

                        {verticalThemesEnabled && verticalThemes !== undefined && (
                          <VerticalThemeSelector
                            options={verticalThemes}
                            selected={selectedVerticalTheme}
                            setSelected={setSelectedVerticalTheme}
                          />
                        )}
                        {holEnabled && (
                          <HolSetup value={numberOfHolParticipants} setValue={setNumberOfHolParticipants} />
                        )}
                      </div>
                    </SetupSection>

                    <SetupSection
                      title="Snowflake"
                      description={`This information is used to connect the instance to a Snowflake account.
                      After connecting we create a unique user for the instance to use for all operations.`}
                    >
                      <div className="w-full max-w-2xl space-y-6">
                        <Switch.Group as="div" className="flex items-center mr-8">
                          <Switch
                            checked={storeAccounts}
                            onChange={() => dispatch(setStoreAccounts(!storeAccounts))}
                            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                storeAccounts ? 'bg-dataops-light-blue' : 'bg-gray-200',
                                'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                              )}
                            />
                            <span
                              aria-hidden="true"
                              className={classNames(
                                storeAccounts ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                              )}
                            />
                          </Switch>
                          <Switch.Label as="span" className="ml-1 text-sm">
                            <span className="font-medium text-gray-700">
                              Save account locator and username for later
                            </span>
                          </Switch.Label>
                        </Switch.Group>
                        <div className="w-3/4">
                          <div className="flex items-center">
                            <label htmlFor="customer-url" className="block text-sm font-medium leading-6 text-gray-900">
                              Snowflake account locator
                            </label>
                            <InformationTooltip tooltip="<account_locator.cloud_region_id.cloud> E.g. xy12345.us-east-2.aws" />
                            <a
                              href="https://docs.snowflake.com/en/user-guide/admin-account-identifier#format-2-legacy-account-locator-in-a-region"
                              target="_blank"
                              rel="noreferrer"
                              className="text-sm hover:underline text-sky-600 ml-2 flex items-center"
                            >
                              Docs
                              <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
                            </a>
                          </div>
                          <PersistedAccountSelector
                            account={account}
                            setAccount={setAccount}
                            setUsername={setUsername}
                            snowflakeAccounts={snowflakeAccounts}
                          />
                        </div>
                        <FormField
                          className="w-3/4"
                          label="Snowflake username"
                          value={snowflakeAccountUsername}
                          disabled={!canSubmit}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                          required={true}
                          testId="snowflake-username-input"
                        />
                        <FormField
                          className="w-3/4"
                          label="Snowflake password"
                          type="password"
                          value={snowflakeAccountPassword}
                          disabled={!canSubmit}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          required={true}
                          testId="snowflake-password-input"
                        />
                      </div>
                      {/* MFA Notice */}
                      <div className="w-3/4 rounded-md bg-blue-50 p-4 mt-8">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <DevicePhoneMobileIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">Have MFA enabled? Have your MFA device ready.</p>
                          </div>
                        </div>
                      </div>
                    </SetupSection>

                    {/* OPTIONAL */}
                    {showOtpField && (
                      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
                        <div className="px-4 sm:px-0">
                          <h2 className="text-base font-semibold leading-7 text-gray-900">One time password</h2>
                          <p className="mt-1 text-sm leading-6 text-gray-600">
                            Enter your in a One Time Password code (OTP) and click create solution to continue
                          </p>
                        </div>

                        <div className="bg-lime-100 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                          <div className="px-4 py-6 sm:p-8">
                            <FormField
                              className="w-1/2"
                              label="One time password"
                              value={snowflakeAccountOtp}
                              disabled={!canSubmit}
                              onChange={(e) => {
                                setOtp(e.target.value);
                              }}
                              required={true}
                              type="number"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {errors !== undefined && errors.length > 0 && <ErrorMessages errors={errors} />}
                  {showProgressMessage && (errors === undefined || errors.length === 0) && (
                    <div className="flex items-center m-4 p-4 rounded-md border border-sky-200 bg-sky-50 text-sky-700 text-sm">
                      {!canSubmit && <Spinner className="mr-1" />}
                      {progressMessages[progressMessageIndex].text}
                    </div>
                  )}
                  <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 bg-white px-4 py-4 sm:px-8">
                    <Button
                      type="button"
                      onClick={() => {
                        setOpen(false);
                        resetForm();
                      }}
                      intent="subtle"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={!canSubmit}
                      intent="primary"
                      size="large"
                      data-testid="setup-submit-button"
                    >
                      Create Solution
                    </Button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import React from 'react';

const Footer = (): JSX.Element => {
  return (
    <div id="footer" className="z-20 bottom-0 left-0 right-0 pl-4 pr-6 py-2 bg-dataops-dark flex items-center">
      <a href="https://www.dataops.live/snowflake-solution-center" target="_blank" rel="noreferrer">
        <img src="/powered-by-dataops.png" className="h-[2.6rem] mr-2" />
      </a>
      <div className="px-6 text-sm text-gray-100 font-semibold border-l border-secondary-text">
        <a href="https://www.truedataops.org/" target="_blank" rel="noreferrer">
          #TrueDataOps
        </a>
      </div>
      <div className="px-6 text-sm text-gray-100 font-semibold border-l border-secondary-text">
        <a href="https://www.dataops.live/dataops-for-dummies-download" target="_blank" rel="noreferrer">
          DataOps for Dummies Ebook
        </a>
      </div>
    </div>
  );
};

export default Footer;

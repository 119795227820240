import React, { useState, useEffect } from 'react';
import { classNames } from '../utilities/styleUtils';

interface ICheckBoxListProps {
  list: string[];
  handleAllItemsChecked: (allItemsChecked: boolean) => void;
}

const maxItems = 5;

export default function CheckBoxList({ list, handleAllItemsChecked }: ICheckBoxListProps) {
  const [itemOverLimit, setItemsOverLimit] = useState(0);
  const [defaultListItems, setListItems] = useState(() => {
    if (list.length > maxItems) {
      setItemsOverLimit(list.length - maxItems);
    }

    return list.slice(0, maxItems).map((item: string, i: number) => {
      return {
        checked: false,
        listItemName: item,
        listItemIndex: i,
      };
    });
  });

  useEffect(() => {
    handleAllItemsChecked(returnTrueIfAllItemsChecked());
  }, [defaultListItems]);

  function updateCheckedState(item: { checked: boolean; listItemName: string; listItemIndex: number }) {
    setListItems((prevState) => {
      return prevState.map((listItem) => {
        if (listItem.listItemIndex === item.listItemIndex) {
          return {
            ...listItem,
            checked: !listItem.checked,
          };
        }
        return listItem;
      });
    });
  }
  function returnTrueIfAllItemsChecked() {
    let allItemsChecked = true;
    defaultListItems.forEach((item) => {
      if (!item.checked) {
        allItemsChecked = false;
      }
    });
    return allItemsChecked;
  }

  return (
    <fieldset>
      <legend className="sr-only">Notifications</legend>
      <div className="space-y-5">
        {defaultListItems.map((item, i: number) => (
          <div className="relative flex items-start" key={`item-${i}`}>
            <div
              className={classNames(
                item.checked ? 'bg-gray-200' : '',
                'flex w-full items-center ps-4 border border-gray-400 hover:bg-gray-100 active:bg-gray-200 rounded dark:border-gray-700',
              )}
            >
              <input
                id={`item-${i}`}
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                checked={item.checked}
                onChange={() => {
                  updateCheckedState(item);
                }}
                className="w-8 h-8 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
              />
              <label htmlFor={`item-${i}`} className="w-full py-4 ms-2 text-base font-semibold text-gray-900 ">
                {item.listItemName}
              </label>
            </div>
          </div>
        ))}
        {itemOverLimit > 0 && (
          <div className="text-sm text-red-500">
            {itemOverLimit === 1 ? (
              <p>
                {itemOverLimit} item hidden. Maximum of {maxItems} allowed.
              </p>
            ) : (
              <p>
                {itemOverLimit} items hidden. Maximum of {maxItems} allowed.
              </p>
            )}
          </div>
        )}
      </div>
    </fieldset>
  );
}

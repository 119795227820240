import React from 'react';
import { cva } from 'class-variance-authority';
import { classNames } from '../utilities/styleUtils';

const spinner = cva('spinner', {
  variants: {
    size: {
      small: ['h-3', 'w-3'],
      medium: ['h-5', 'w-5'],
      large: ['h-8', 'w-8'],
      xLarge: ['h-20', 'w-20'],
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

export interface SpinnerProps extends React.SVGAttributes<SVGSVGElement> {
  size?: 'small' | 'medium' | 'large' | 'xLarge';
}

const Spinner: React.FC<SpinnerProps> = ({ className, size, ...props }) => {
  return (
    <svg className={classNames('animate-spin', className, spinner({ size }))} viewBox="0 0 24 24">
      <circle className="opacity-0" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export default Spinner;

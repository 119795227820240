import React, { useMemo, useState } from 'react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { getEnvVariableValue } from '../utilities/parsers';
import Button from './Button';
import { classNames } from '../utilities/styleUtils';
import type { IDeployment } from '../reducers/deploymentsSlice';

async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(text);
  } else {
    document.execCommand('copy', true, text);
  }
}

interface IHolParticipantDetailsProps {
  projectInstance: IDeployment;
}

export default function HolParticipantDetails(props: IHolParticipantDetailsProps) {
  const [showTable, setShowTable] = useState<boolean>(false);
  const participants = useMemo(() => {
    const usernames = getEnvVariableValue(props.projectInstance.ciVariables, 'DATAOPS_CATALOG_HOL_USERNAMES');
    const passwords = getEnvVariableValue(props.projectInstance.ciVariables, 'DATAOPS_CATALOG_HOL_PASSWORDS');

    if (usernames === '' || passwords === '') {
      return [];
    }

    const usersSplit = usernames.split(',').map((username) => username.trim());
    const passwordsSplit = passwords.split(',').map((password) => password.trim());

    if (usersSplit.length !== passwordsSplit.length) {
      return [];
    }

    const usersWithPassword = usersSplit.map((user, index) => {
      return { username: user, password: passwordsSplit[index] };
    });
    return usersWithPassword;
  }, [props.projectInstance]);

  if (participants.length === 0) {
    return <></>;
  }

  return (
    <div className="px-6">
      <div
        className="hover:text-gray-900 cursor-pointer flex items-center"
        onClick={() => {
          setShowTable(!showTable);
        }}
      >
        <span className="text-sm font-medium text-gray-500">Participants</span>
        <ChevronUpIcon
          className={classNames(
            showTable ? 'rotate-180 transform' : 'rotate-90 transform',
            'h-4 w-4 ml-1 text-gray-500',
          )}
        />
      </div>
      {showTable && (
        <div className="mt-2 mb-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-1 pl-1 pr-1 text-left text-sm font-semibold text-gray-900">
                  Username
                </th>
                <th scope="col" className="px-1 py-1 text-left text-sm font-semibold text-gray-900">
                  Password
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {participants.map((participant) => (
                <tr key={participant.username}>
                  <td className="whitespace-nowrap px-1 py-1 text-sm text-gray-500">{participant.username}</td>
                  <td className="whitespace-nowrap px-1 py-1 text-sm text-gray-500">
                    <Button
                      onClick={() => {
                        copyTextToClipboard(participant.password).catch((e) => e);
                      }}
                      intent="light"
                      size="small"
                      className="!py-0.5 !text-xs"
                    >
                      Copy password
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../apolloClient';
import { gql } from '@apollo/client';
import type { ICurrentUser } from '../interfaces/ICurrentUser';
import { type IPipeline } from './deploymentsSlice';

interface IAccountState {
  currentUser?: ICurrentUser;
  broadcastIdsAcknowledged: number[];
  requestStatus?: 'idle' | 'loading' | 'failed';
  docsPipelines?: IPipeline[];
}

const initialState: IAccountState = {
  broadcastIdsAcknowledged: [],
};

export const fetchDocsPipelines = createAsyncThunk('docs/fetch', async (token: string) => {
  const GET_DOCS_PIPELINES = gql`
    query getDocsPipelines {
      project(fullPath: "snowflake/shared/documentation") {
        pipelines(ref: "main") {
          nodes {
            id
            path
            jobs {
              nodes {
                id
                name
                status
              }
            }
          }
        }
      }
    }
  `;
  let response: any = { data: { pipelines: [] } };
  try {
    response = await client.query({
      query: GET_DOCS_PIPELINES,
      context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getDocsPipelines' } },
    });
  } catch (error) {
    console.error('docs error', error);
  }
  return response.data.project?.pipelines.nodes.map((pipeline: any) => {
    return {
      id: pipeline.id,
      path: pipeline.path,
      jobs: pipeline.jobs.nodes.map((job: any) => {
        return {
          id: job.id,
          name: job.name,
          status: job.status,
        };
      }),
    };
  });
});

export const fetchUserStars = createAsyncThunk('user/fetch/stars', async (token: string) => {
  const GET_USER_STARS = gql`
    query getUserStars {
      currentUser {
        starredProjects {
          nodes {
            id
          }
        }
      }
    }
  `;
  const response = await client.query({
    query: GET_USER_STARS,
    context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getUserStars' } },
  });
  return response.data.currentUser;
});

export const fetchUser = createAsyncThunk('user/fetch', async (token: string) => {
  const GET_USER = gql`
    query getUser {
      currentUser {
        id
        username
        avatarUrl
        emails {
          nodes {
            email
          }
        }
        starredProjects {
          nodes {
            id
          }
        }
      }
    }
  `;
  const response = await client.query({
    query: GET_USER,
    context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getUser' } },
  });
  return response.data.currentUser;
});

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setBroadcastAcknowledged: (state, action) => {
      state.broadcastIdsAcknowledged = action.payload;
    },
    setBroadcastReset: (state) => {
      state.broadcastIdsAcknowledged = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.requestStatus = 'idle';
        state.currentUser = action.payload;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.requestStatus = 'failed';
      });
    builder
      .addCase(fetchUserStars.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(fetchUserStars.fulfilled, (state, action) => {
        state.requestStatus = 'idle';
        if (state.currentUser !== undefined && action.payload.starredProjects !== undefined) {
          state.currentUser.starredProjects.nodes = action.payload.starredProjects.nodes;
        }
      })
      .addCase(fetchUserStars.rejected, (state) => {
        state.requestStatus = 'failed';
      });
    builder
      .addCase(fetchDocsPipelines.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(fetchDocsPipelines.fulfilled, (state, action) => {
        state.requestStatus = 'idle';
        state.docsPipelines = action.payload;
      })
      .addCase(fetchDocsPipelines.rejected, (state) => {
        state.requestStatus = 'failed';
      });
  },
});

export const { setBroadcastAcknowledged, setBroadcastReset } = accountSlice.actions;

export const selectAcknowledgedBroadcastIds = (state: { account: IAccountState }) =>
  state.account.broadcastIdsAcknowledged;
export const selectUser = (state: { account: IAccountState }) => state.account.currentUser;
export const selectDocsPipelines = (state: { account: IAccountState }) => state.account.docsPipelines;

export default accountSlice.reducer;

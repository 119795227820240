import { useEffect, useState } from 'react';
import type { IMainPipelineStatus } from '../interfaces/IMainPipelineStatus';
import type { IValidationPipelineStatus } from '../interfaces/IValidationPipelineStatus';
import {
  getMainPipeline,
  getValidationPipeline,
  isPipelineActive,
  isPipelineSuccess,
  isValidateCiFileExists,
} from '../utilities/pipelines';
import type { IDeployment } from '../reducers/deploymentsSlice';

export function useValidationPipelineStatus(projectInstance: IDeployment): IValidationPipelineStatus {
  const [pipelineStatus, setPipelineStatus] = useState<IValidationPipelineStatus>('ANALYZING_STATUS');

  useEffect(() => {
    const pipelines = projectInstance.pipelines;
    if (pipelines?.length === 0) {
      setPipelineStatus('ANALYZING_STATUS');
      return;
    }
    if (!isValidateCiFileExists(projectInstance)) {
      setPipelineStatus('MISSING_VALIDATE_CI_FILE');
      return;
    }
    const pipeline = getValidationPipeline(pipelines);
    if (pipeline === undefined) {
      setPipelineStatus('MISSING_PIPELINE');
      return;
    }
    if (isPipelineActive(pipeline)) {
      setPipelineStatus('VALIDATION_IN_PROGRESS');
      return;
    }
    if (!isPipelineSuccess(pipeline)) {
      setPipelineStatus('VALIDATION_FAILED');
      return;
    }
    setPipelineStatus('VALIDATION_SUCCESS');
  }, [projectInstance.pipelines]);

  return pipelineStatus;
}

export function useMainPipelineStatus(projectInstance: IDeployment): IMainPipelineStatus {
  const [pipelineStatus, setPipelineStatus] = useState<IMainPipelineStatus>('ANALYZING_STATUS');

  useEffect(() => {
    if (projectInstance.pipelines === undefined) {
      return;
    }
    const pipelines = projectInstance.pipelines;
    if (pipelines.length === 0) {
      setPipelineStatus('ANALYZING_STATUS');
      return;
    }
    const pipeline = getMainPipeline(pipelines);
    if (pipeline === undefined) {
      setPipelineStatus('ANALYZING_STATUS');
      return;
    }
    if (isPipelineActive(pipeline)) {
      setPipelineStatus('SETUP_IN_PROGRESS');
      return;
    }
    if (!isPipelineSuccess(pipeline)) {
      setPipelineStatus('SETUP_FAILED');
      return;
    }

    setPipelineStatus('SETUP_SUCCESS');
  }, [projectInstance]);

  return pipelineStatus;
}

import React from 'react';

export default function ErrorFallback() {
  return (
    <div className="bg-gray-100">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        ></div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              There has been an error :&#40;
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              We have sent this error to DataOps.live support already. Refresh the page.
            </p>
            <p className=" text-lg leading-8 text-gray-600">If the problem persists context support.</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <button
                className="rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh this window
              </button>
              <a
                href="mailto:support@dataops.live?subject=SSC app load error&body=Please describe the error you are experiencing."
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Email support <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        ></div>
      </div>
    </div>
  );
}

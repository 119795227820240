import React from 'react';

export default function ProjectCardSkeleton() {
  return (
    <div className="project overflow-hidden bg-white shadow sm:rounded-lg hover:bg-sky-50 divide-y">
      <div className="px-4 py-3 sm:px-6 flex flex-row content-center items-center">
        <div className="shrink-0 ">
          <div className="w-8">
            <div className="w-8 h-8 bg-gray-200 rounded-full animate-pulse"></div>
          </div>
        </div>
        <div>
          <h3 className="ml-4 text-xl font-semibold leading-6 text-gray-900 hover:text-sky-500">
            <div className="w-40 h-6 bg-gray-200 rounded animate-pulse"></div>
          </h3>
        </div>
        <div className="flex-1 flex justify-end ml-[auto] items-center space-x-1">
          <div className="w-16 h-6 bg-gray-200 rounded-md animate-pulse"></div>
          <div className="w-16 h-6 bg-gray-200 rounded-md animate-pulse"></div>
          <div className="w-16 h-6 bg-gray-200 rounded-md animate-pulse"></div>
        </div>
      </div>
      <div className="flex flex-row p-6 space-x-4">
        <div id="details" className="flex flex-col grow space-y-4">
          <div>
            <dl className="w-auto">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="text-sm text-gray-900">
                <div className="w-40 h-6 bg-gray-200 rounded animate-pulse"></div>
              </dd>
            </dl>
          </div>
          <div className="flex flex-row">
            <dl className="basis-1/3">
              <dt className="text-sm font-medium text-gray-500">Industry vertical</dt>
              <dd className="text-sm text-gray-900">
                <div className="w-40 h-6 bg-gray-200 rounded animate-pulse"></div>
              </dd>
            </dl>
            <dl className="basis-1/3">
              <dt className="text-sm font-medium text-gray-500">Use case</dt>
              <dd className="text-sm text-gray-900">
                <div className="w-40 h-6 bg-gray-200 rounded animate-pulse"></div>
              </dd>
            </dl>
          </div>
        </div>
        <div id="controls" className="basis-60 shrink-0 space-y-2">
          <div className="">
            <dd className="text-sm text-gray-900 space-y-1">
              <div className="w-full h-10 bg-gray-200 rounded animate-pulse"></div>
              <div className="w-full h-10 bg-gray-200 rounded animate-pulse"></div>
              <div className="w-full h-10 bg-gray-200 rounded animate-pulse"></div>
            </dd>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, Fragment } from 'react';

import { ArrowTopRightOnSquareIcon, CheckCircleIcon, RocketLaunchIcon, XCircleIcon } from '@heroicons/react/20/solid';
import Button from './Button';
import { sendMetrics } from '../utilities/analytics';
import HomepageButtonGroup from './HomepageButtonGroup';
import type { IDeployment } from '../reducers/deploymentsSlice';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';

interface ISetupInProgressModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  cancelButtonRef: React.RefObject<HTMLButtonElement>;
  mainPipelineStatus: string;
  opportunityId: string;
  projectPipelinesUrl: string | undefined;
  solutionHomepageUrl: string | undefined;
  username: string;
  projectDeployment: IDeployment;
  dataopsProjectID: string;
  refreshProjects: () => void;
}

export default function HomepageLaunchModal({
  mainPipelineStatus,
  opportunityId,
  projectPipelinesUrl,
  solutionHomepageUrl,
  cancelButtonRef,
  open,
  setOpen,
  refreshProjects,
  username,
  projectDeployment,
  dataopsProjectID,
}: ISetupInProgressModalProps) {
  const [opportunityIdValue, setOpportunityIdValue] = useState<string>(opportunityId);
  const [showDemoPurpose, setShowDemoPurpose] = useState<boolean>(false);

  const onClose = () => {
    setOpen(false);
    setShowDemoPurpose(false);
    refreshProjects();
  };

  const onNavigateToSolutionHomepage = (demoPurpose: 'Personal' | 'Customer' | 'LargeAudience') => {
    onClose();

    const analyticsData = {
      event_name: 'dataops-solution-homepage-navigate-to-solution-homepage',
      event_source: 'frostbyte-deployment-portal',
      properties: {
        username,
        project_name: projectDeployment,
        dataops_project_id: dataopsProjectID,
        demo_purpose: demoPurpose,
        opportunity_id: opportunityIdValue,
      },
    };
    sendMetrics(analyticsData);

    window.open(solutionHomepageUrl, '_blank')?.focus();
  };
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={() => {
          onClose();
        }}
      >
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="p-6 relative transform rounded-lg bg-white text-left shadow-xl transition-all my-8 max-w-xl">
                <div>
                  {mainPipelineStatus === 'SETUP_SUCCESS' && (
                    <div>
                      <div className="mx-auto flex items-center space-x-2">
                        <div className="flex flex-row">
                          <CheckCircleIcon className="w-6 h-6 text-lime-600" />
                        </div>
                        <DialogTitle className="flex text-base justify-center">Deployment homepage ready</DialogTitle>
                      </div>
                      <HomepageButtonGroup
                        projectDeployment={projectDeployment}
                        onNavigateToSolutionHomepage={onNavigateToSolutionHomepage}
                        opportunityId={opportunityId}
                        setOpportunityId={setOpportunityIdValue}
                        onBack={onClose}
                      />
                    </div>
                  )}
                  {mainPipelineStatus === 'SETUP_IN_PROGRESS' && (
                    <>
                      <div className="mx-auto flex items-center space-x-2">
                        <div className="flex flex-row">
                          <RocketLaunchIcon className="w-6 h-6 text-sky-600" />
                        </div>
                        <DialogTitle className="flex text-base justify-center">Setup in progress</DialogTitle>
                      </div>

                      {!showDemoPurpose && (
                        <>
                          <div className="mt-3 sm:mt-5">
                            <div className="mt-3">
                              <p className="text-sm text-gray-500">
                                Your solution instance setup is in progress. You can see your homepage early but the
                                vignettes and demo&apos;s should not be run until the setup is complete.
                              </p>
                              <p className="mt-3 text-sm text-gray-500">
                                This dialog will auto-update and show you when setup is complete.
                              </p>
                            </div>
                          </div>
                          <ShowDemoPurposeButtons onClose={onClose} setShowDemoPurpose={setShowDemoPurpose} />
                        </>
                      )}
                      {showDemoPurpose && (
                        <HomepageButtonGroup
                          projectDeployment={projectDeployment}
                          onNavigateToSolutionHomepage={onNavigateToSolutionHomepage}
                          opportunityId={opportunityId}
                          setOpportunityId={setOpportunityIdValue}
                          onBack={() => {
                            setShowDemoPurpose(false);
                          }}
                        />
                      )}
                    </>
                  )}
                  {mainPipelineStatus === 'SETUP_FAILED' && (
                    <>
                      <div className="mx-auto flex items-center space-x-2">
                        <div className="flex flex-row">
                          <XCircleIcon className="w-6 h-6 text-dataops-dark-red" />
                        </div>
                        <DialogTitle className="flex text-base justify-center">Setup issue</DialogTitle>
                      </div>
                      {!showDemoPurpose && (
                        <>
                          <div className="mt-3 sm:mt-5">
                            <div className="mt-3">
                              <p className="flex mt-3 text-sm text-gray-500">
                                <a
                                  href={projectPipelinesUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="flex text-sky-600 underline"
                                >
                                  Your solution deployment pipeline
                                  <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
                                </a>
                              </p>
                            </div>
                          </div>
                          <ShowDemoPurposeButtons onClose={onClose} setShowDemoPurpose={setShowDemoPurpose} />
                        </>
                      )}
                      {showDemoPurpose && (
                        <HomepageButtonGroup
                          projectDeployment={projectDeployment}
                          onNavigateToSolutionHomepage={onNavigateToSolutionHomepage}
                          setOpportunityId={setOpportunityIdValue}
                          opportunityId={opportunityId}
                          onBack={() => {
                            setShowDemoPurpose(false);
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

interface ShowDemoPurposeButtonsProps {
  onClose: () => void;
  setShowDemoPurpose: (value: boolean) => void;
}

function ShowDemoPurposeButtons({ onClose, setShowDemoPurpose }: ShowDemoPurposeButtonsProps) {
  return (
    <div className="mt-4 space-y-2">
      <Button
        size="large"
        intent="light"
        className="w-full justify-center"
        onClick={() => {
          setShowDemoPurpose(true);
        }}
      >
        Continue anyway
        <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
      </Button>
      <Button
        size="large"
        intent="subtle"
        className="w-full justify-center"
        onClick={() => {
          onClose();
        }}
      >
        Back
      </Button>
    </div>
  );
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPersistedSnowflakeAccounts, setPersistedSnowflakeAccounts } from '../reducers/projectsSlice';
import type { ISnowflakeAccount } from '../interfaces/ISnowflakeAccount';

export default function useStoredAccounts(): {
  accounts: ISnowflakeAccount[];
  addAccount: (account: ISnowflakeAccount) => void;
  updateAccount: (previousAccount: ISnowflakeAccount, updatedAccount: ISnowflakeAccount) => void;
  removeAccount: (account: ISnowflakeAccount) => void;
} {
  const dispatch = useDispatch();
  const accounts = useSelector(selectPersistedSnowflakeAccounts);

  // This implementation of the addAccount function allows only a single account with a given account locator
  //   const addAccount = (account: ISnowflakeAccount) => {
  //     const existingIndex = accounts.findIndex((a) => a.accountLocator === account.accountLocator);
  //     if (existingIndex > -1) {
  //       const newAccounts = [...accounts];
  //       newAccounts[existingIndex] = account;
  //       dispatch(setSnowflakeAccounts(newAccounts));
  //       localStorage.setItem('accounts', JSON.stringify(newAccounts));
  //     } else {
  //       const newAccounts = [...accounts, account];
  //       dispatch(setSnowflakeAccounts(newAccounts));
  //       localStorage.setItem('accounts', JSON.stringify(newAccounts));
  //     }
  //   };

  const addAccount = (account: ISnowflakeAccount) => {
    const existingIndex = accounts.findIndex((a) => a.accountLocator === account.accountLocator);
    if (existingIndex === -1) {
      const newAccounts = [...accounts, account];
      dispatch(setPersistedSnowflakeAccounts(newAccounts));
      localStorage.setItem('accounts', JSON.stringify(newAccounts));
    }
  };

  const updateAccount = (previousAccount: ISnowflakeAccount, updatedAccount: ISnowflakeAccount) => {
    if (
      previousAccount.accountLocator === updatedAccount.accountLocator &&
      previousAccount.username === updatedAccount.username
    ) {
      return;
    }

    const originalIndex = accounts.findIndex(
      (a) => a.accountLocator === previousAccount.accountLocator && a.username === previousAccount.username,
    );
    const existingIndex = accounts.findIndex(
      (a) => a.accountLocator === updatedAccount.accountLocator && a.username === updatedAccount.username,
    );
    if (existingIndex !== -1) {
      let newAccounts = [...accounts];
      newAccounts = newAccounts.filter(
        (a) => a.accountLocator !== previousAccount.accountLocator || a.username !== previousAccount.username,
      );
      dispatch(setPersistedSnowflakeAccounts(newAccounts));
      localStorage.setItem('accounts', JSON.stringify(newAccounts));
    } else if (originalIndex !== -1) {
      const newAccounts = [...accounts];
      newAccounts[originalIndex] = updatedAccount;
      dispatch(setPersistedSnowflakeAccounts(newAccounts));
      localStorage.setItem('accounts', JSON.stringify(newAccounts));
    }
  };

  const removeAccount = (account: ISnowflakeAccount) => {
    const newAccounts = accounts.filter(
      (a) => a.accountLocator !== account.accountLocator || a.username !== account.username,
    );
    dispatch(setPersistedSnowflakeAccounts(newAccounts));
    localStorage.setItem('accounts', JSON.stringify(newAccounts));
  };

  useEffect(() => {
    try {
      const accountsFromStorage = localStorage.getItem('accounts');
      dispatch(setPersistedSnowflakeAccounts(JSON.parse(accountsFromStorage ?? '[]')));
    } catch (e) {
      console.error('Error loading accounts from local storage', e);
      dispatch(setPersistedSnowflakeAccounts([]));
    }
  }, []);

  return { accounts, addAccount, updateAccount, removeAccount };
}

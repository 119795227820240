import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Disclosure, Menu, Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { ChevronDownIcon, DocumentTextIcon, ExclamationTriangleIcon, LifebuoyIcon } from '@heroicons/react/24/outline';
import ExperimentalFeatureToggle from './ExperimentalFeatureToggle';
import AppSwitcher from './AppSwitcher';
import { classNames } from '../utilities/styleUtils';
import { useAppSelector, useAppDispatch } from '../hooks';
import {
  selectVerticalThemesEnabled,
  setVerticalThemesEnabled,
  selectHolEnabled,
  setHolEnabled,
  selectSocialFeaturesEnabled,
  setSocialFeaturesEnabled,
} from '../reducers/projectsSlice';
import Button from './Button';
import ProjectDeploymentsModal from './ProjectDeploymentsModal';
import { getConfig } from '../config/config-helper';
import { parseGuid } from '../utilities/parsers';
import { getJobByNames, getMainPipeline } from '../utilities/pipelines';
import { AcademicCapIcon } from '@heroicons/react/20/solid';
import { selectDocsPipelines, setBroadcastReset } from '../reducers/accountSlice';
import { selectDeployments, selectRequestStatus } from '../reducers/deploymentsSlice';
import snowflakeLogo from '../assets/snowflake-logo-white.svg';

interface IDocMenuItem {
  name: string;
  href: string;
  icon: any;
  description: string;
}

function DocsPopover({ items }: { items: IDocMenuItem[] }) {
  return (
    <div className=" max-w-sm">
      <Popover className="">
        {({ open }) => (
          <>
            <PopoverButton
              className={`
                ${open ? 'text-white' : 'text-white/90'}
                group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-white hover:bg-hover-dark-blue disabled:text-gray-400 disabled:hover:bg-gray-800`}
              data-testid="docs-popover-button"
            >
              <DocumentTextIcon className="w-5 h-5" />
              <span className="ml-1 flex items-center">Docs</span>
              <ChevronDownIcon
                className={`${open ? 'text-sky-300' : 'text-sky-300/70'}
                  ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-sky-300/80`}
                aria-hidden="true"
              />
            </PopoverButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute z-10 w-96 right-0 top-14">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                  <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-1">
                    {items
                      .filter((item) => {
                        return item.href !== '';
                      })
                      .map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/50"
                          target="_blank"
                          rel="noreferrer"
                          data-testid={`docs-popover-item-${item.name}`}
                        >
                          <div className="flex h-10 w-10 shrink-0 items-center justify-center text-dataops-light-blue sm:h-12 sm:w-12">
                            {item.icon}
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium text-gray-900">{item.name}</p>
                            <p className="text-sm text-gray-500">{item.description}</p>
                          </div>
                        </a>
                      ))}
                  </div>
                  <div className="bg-gray-50 p-4">
                    <a
                      href="https://www.dataops.live/snowflake-solution-center"
                      className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/50"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="flex items-center">
                        <span className="text-sm font-medium text-gray-900">
                          Snowflake Solution Central + DataOps.live
                        </span>
                      </span>
                      <span className="block text-sm text-gray-500">Read about SSC powered by DataOps.live</span>
                    </a>
                  </div>
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

interface AppBarProps {
  username?: string;
  logOut: () => void;
  refreshProjects: () => void;
}

function AppBar({ username, logOut, refreshProjects }: AppBarProps) {
  const usernameInitials = useMemo(() => {
    if (username === undefined) {
      return '';
    }
    const initials = username.split(' ').map((name) => name.charAt(0).toUpperCase());
    return initials.join('');
  }, [username]);
  const { dataopsliveBaseUrl } = getConfig();
  const dispatch = useAppDispatch();
  const verticalThemesEnabled = useAppSelector(selectVerticalThemesEnabled);
  const holEnabled = useAppSelector(selectHolEnabled);
  const socialFeaturesEnabled = useAppSelector(selectSocialFeaturesEnabled);
  const deployments = useAppSelector(selectDeployments);
  const requestStatus = useAppSelector(selectRequestStatus);
  const [projectDeploymentsOpen, setProjectDeploymentsOpen] = useState<boolean>(false);
  const showExperimentalFeatures = process.env.REACT_APP_ENV !== 'production';
  const docsPipelines = useAppSelector(selectDocsPipelines);
  const [docsUrl, setDocsUrl] = useState<string>('');
  const docsProjectPath = 'snowflake/shared/documentation';

  const docsMenuItems: IDocMenuItem[] = [
    { name: 'System Docs', href: docsUrl, icon: <LifebuoyIcon />, description: 'Learn more about using SSC.' },
    {
      name: 'DataOps.live Docs',
      href: 'https://docs.dataops.live/docs/get-started/',
      icon: <AcademicCapIcon />,
      description: 'Learn more about using DataOps.live.',
    },
  ];

  const latestDocsPipeline = getMainPipeline(docsPipelines, 'Render SSC Documentation');
  useEffect(() => {
    if (latestDocsPipeline !== undefined) {
      const pipelineId = parseGuid(latestDocsPipeline.id);
      const job = getJobByNames(latestDocsPipeline, ['Render SSC Documentation']);
      const jobId = job !== undefined ? parseGuid(job.id) : null;
      setDocsUrl(`${dataopsliveBaseUrl}/${docsProjectPath}/documentations/${pipelineId}?job=${jobId ?? ''}`);
    }
  }, [latestDocsPipeline]);

  return (
    <Disclosure as="nav" id="appbar" className="bg-dataops-dark h-16 w-screen fixed top-0 z-40">
      <ProjectDeploymentsModal
        open={projectDeploymentsOpen}
        setOpen={setProjectDeploymentsOpen}
        projects={deployments}
        refreshProjects={refreshProjects}
      />
      <div className="w-full min-w-[720px] h-full pl-[0.8rem] pr-2 sm:pr-4 lg:pr-8">
        {requestStatus === 'loading' && <div className="w-full h-1 absolute top-0 left-0 bg-in-progress"></div>}
        <div className="w-full h-full relative flex items-center space-x-2">
          <div className="flex-grow flex items-center px-2 lg:px-0 space-x-2">
            <AppSwitcher />
            <div className="flex-shrink-0 px-4">
              <a href="https://www.dataops.live/snowflake-solution-center" target="_blank" rel="noreferrer">
                <img className="h-8 w-auto" src="/dataops-logo-with-tagline.svg" alt="DataOps.live" />
              </a>
            </div>
            <div className="ml-6 px-6 border-l border-r border-secondary-text">
              <a href="/">
                <img className="h-[1.6rem] w-[auto]" src={snowflakeLogo} alt="snowflake logo white" />
              </a>
            </div>
            <div className="px-4 text-sm text-gray-100 font-semibold">
              <a href="/">Solution Central</a>
            </div>
            <Button
              onClick={() => {
                setProjectDeploymentsOpen(true);
              }}
              size={'large'}
              className="space-x-1"
              data-testid="nav-deployments-button"
            >
              <span>Deployments</span>
              <span>({deployments.length})</span>
            </Button>
          </div>
          <DocsPopover items={docsMenuItems} />
          <div>
            <Menu as="div" className="relative">
              <div>
                <Menu.Button className="flex rounded-full bg-gray-800 text-sm text-white">
                  <span className="sr-only">Open user menu</span>
                  <div
                    className={classNames(
                      username !== '' || username !== undefined
                        ? 'bg-dataops-light-blue hover:bg-hover-light-blue'
                        : 'bg-yellow-500 hover:bg-yellow-600',
                      'w-9 h-9 flex items-center justify-center rounded-full text-2xl font-medium text-gray-50',
                    )}
                  >
                    {username !== '' || username !== undefined ? (
                      <div>{usernameInitials}</div>
                    ) : (
                      <ExclamationTriangleIcon className="w-5 h-5" />
                    )}
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          logOut();
                        }}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'w-full block px-4 py-2 text-left text-sm text-gray-700',
                        )}
                      >
                        Refresh auth
                      </button>
                    )}
                  </Menu.Item>
                  {window.Appcues !== undefined && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            window.Appcues.show('8c7d595b-cb44-4927-b973-a5318db641a2');
                          }}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'w-full block px-4 py-2 text-left text-sm text-gray-700',
                          )}
                        >
                          Reset welcome tour
                        </button>
                      )}
                    </Menu.Item>
                  )}
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          dispatch(setBroadcastReset());
                        }}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'w-full block px-4 py-2 text-left text-sm text-gray-700',
                        )}
                      >
                        Reset broadcasts
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    <div className="px-2">
                      <ExperimentalFeatureToggle
                        label="Social features"
                        checked={socialFeaturesEnabled}
                        onChange={() => dispatch(setSocialFeaturesEnabled(!socialFeaturesEnabled))}
                      />
                    </div>
                  </Menu.Item>
                  {showExperimentalFeatures && (
                    <Menu.Item>
                      <div className="px-4 py-2">
                        <div className="font-medium text-sm text-gray-800">Experimental features</div>
                        <ExperimentalFeatureToggle
                          label="Vertical themes"
                          checked={verticalThemesEnabled}
                          onChange={() => dispatch(setVerticalThemesEnabled(!verticalThemesEnabled))}
                        />
                        <ExperimentalFeatureToggle
                          label="Hands-on labs"
                          checked={holEnabled}
                          onChange={() => dispatch(setHolEnabled(!holEnabled))}
                        />
                      </div>
                    </Menu.Item>
                  )}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </Disclosure>
  );
}

export default AppBar;

import { ApolloClient, InMemoryCache, type DefaultOptions } from '@apollo/client';
import { getConfig } from './config/config-helper';

const { dataopsliveBaseUrl } = getConfig();

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  uri: `${dataopsliveBaseUrl}/api/graphql`,
  cache: new InMemoryCache(),
  defaultOptions,
  headers: {
    'DataOps-Component': 'SSC',
  },
});

export default client;

import { gql } from '@apollo/client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../apolloClient';
import { parseGuid } from '../utilities/parsers';

// #region Interfaces

export interface IEnvironment {
  id: string;
  guid: string;
}

export interface ISolution {
  id: string;
  guid: string;
  fullPath: string;
  starCount: number;
  // --- Extra ---
  requestExtraStatus?: 'empty' | 'filled' | 'loading' | 'failed';
  environments?: IEnvironment[];
}

interface ISolutionsState {
  solutions: ISolution[];
  requestStatus?: 'idle' | 'loading' | 'failed';
}

const initialState: ISolutionsState = {
  solutions: [],
};

// #region Fetch solution
export const fetchSolution = createAsyncThunk(
  'solution/fetch',
  async (props: { token: string; fullPath: string }): Promise<ISolution> => {
    const { token, fullPath } = props;
    const GET_SOLUTION = gql`
      query getSolution {
        project(fullPath: "${fullPath}") {
          id
          fullPath
          starCount
          environments {
            nodes {
              id
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    `;
    const solutionFetchResponse = await client.query({
      query: GET_SOLUTION,
      context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getSolution' } },
    });

    return {
      id: parseGuid(solutionFetchResponse.data.project.id),
      guid: solutionFetchResponse.data.project.id,
      fullPath: solutionFetchResponse.data.project.fullPath,
      starCount: solutionFetchResponse.data.project.starCount,
      requestExtraStatus: 'empty',
      environments: solutionFetchResponse.data.project.environments.nodes.map((env: any) => ({
        id: parseGuid(env.id),
        guid: env.id,
      })),
    };
  },
);

// #region Reducer
export const solutionsSlice = createSlice({
  name: 'solutions',
  initialState,
  reducers: {
    deleteDeployment: (state, action) => {
      state.solutions = state.solutions.filter((s) => s.guid !== action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSolution.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(fetchSolution.fulfilled, (state, action) => {
        const solution = state.solutions.find((s) => s.guid === action.payload.guid);
        if (solution !== undefined) {
          solution.starCount = action.payload.starCount;
          solution.requestExtraStatus = 'filled';
          solution.environments = action.payload.environments;
        } else {
          state.solutions.push(action.payload);
        }
        state.requestStatus = 'idle';
      })
      .addCase(fetchSolution.rejected, (state) => {
        state.requestStatus = 'failed';
      });
  },
});

export const { deleteDeployment } = solutionsSlice.actions;

export const selectSolution = (state: { solutions: ISolutionsState }, projectId: string): ISolution => {
  return state.solutions.solutions.find((s) => s.id === projectId) as ISolution;
};

export default solutionsSlice.reducer;

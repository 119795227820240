import React from 'react';

interface FilterInputProps {
  filter: string;
  setFilter: (filter: string) => void;
}

export default function FilterInput({ filter, setFilter }: FilterInputProps) {
  return (
    <div className="h-10">
      <label htmlFor="filter-field" className="sr-only">
        Filter by name
      </label>
      <input
        id="filter-field"
        className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm focus-visible:outline-dataops-light-blue"
        placeholder="Filter by name..."
        type="Filter"
        name="filter"
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        value={filter}
      />
    </div>
  );
}

import React, { Fragment } from 'react';
import { Dialog, DialogPanel, TabGroup, TabPanel, TabPanels, Transition, TransitionChild } from '@headlessui/react';
import { type IDeployment } from '../reducers/deploymentsSlice';
import ShareCodeWithCustomer from './ShareCodeWithCustomer';
import ShareSolutionLink from './ShareSolutionLink';
import { type ISolution } from '../reducers/solutionsSlice';
import ShareDeployment from './ShareDeployment';

interface IShareModalProps {
  open: boolean;
  closeModal: () => void;
  deployment?: IDeployment;
  solution: ISolution;
}

export default function ShareModal({ open, closeModal, deployment, solution }: IShareModalProps) {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={() => {
          closeModal();
        }}
      >
        <TransitionChild
          as="div"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative w-[530px] transform rounded-lg bg-white text-left shadow-xl transition-all my-8">
                <TabGroup>
                  {/* Disabled tabs until we have more share modes. */}
                  {/* <TabList className={'flex p-6 space-x-4'}>
                    {[
                      { name: 'Code with Customer', disabled: false },
                      { name: 'Deployment', disabled: true },
                      { name: 'Solution', disabled: true },
                    ].map((tabData) => (
                      <Tab
                        key={tabData.name}
                        disabled={tabData.disabled}
                        className={`hidden rounded-md px-3 py-2 text-sm font-bold text-dataops-dark-blue/80 data-[selected]:bg-dataops-light-blue data-[selected]:text-white ring-inset ring-1 ring-dataops-light-blue/20 hover:text-dataops-dark-blue hover:bg-dataops-light-blue/20 disabled:hover:bg-inherit disabled:ring-gray-200 disabled:text-gray-400`}
                      >
                        {tabData.name}
                      </Tab>
                    ))}
                  </TabList> */}
                  <TabPanels>
                    <TabPanel>
                      <ShareCodeWithCustomer projectDeployment={deployment} closeModal={closeModal} />
                    </TabPanel>
                    <TabPanel>
                      <ShareDeployment />
                    </TabPanel>
                    <TabPanel>
                      <ShareSolutionLink solution={solution} />
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export function ModalFooter({ title, children }: { title: string; children?: React.ReactNode }) {
  return (
    <div className="py-6 bg-gray-200 px-4 sm:flex sm:flex-col sm:px-6 space-y-3 rounded-b-lg shadow-inner">
      <h3 className="text-gray-800 font-bold subpixel-antialiased">{title}</h3>
      {children}
    </div>
  );
}

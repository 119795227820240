import React from 'react';

export interface IPageState {
  currentPage: number;
  itemsPerPage: number;
}

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export default function Pagination({ totalItems, itemsPerPage, currentPage, onPageChange }: PaginationProps) {
  const fromItem = (currentPage - 1) * itemsPerPage + 1;
  const fromItemZero = totalItems === 0 ? 0 : fromItem;
  const toItem = Math.min(currentPage * itemsPerPage, totalItems);
  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{fromItemZero}</span> to <span className="font-medium">{toItem}</span>{' '}
          of <span className="font-medium">{totalItems}</span> results
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <button
          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:text-gray-400 disabled:cursor-not-allowed"
          disabled={currentPage === 1}
          onClick={() => {
            onPageChange(currentPage - 1);
          }}
        >
          Previous
        </button>
        <button
          className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:text-gray-400 disabled:cursor-not-allowed"
          disabled={toItem === totalItems}
          onClick={() => {
            onPageChange(currentPage + 1);
          }}
        >
          Next
        </button>
      </div>
    </nav>
  );
}

import React, { useState, Fragment } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useAppSelector, useAppDispatch } from '../hooks';
import { selectPersistedAccountViewerOpen, setPersistedAccountViewerOpen } from '../reducers/projectsSlice';
import SnowflakeAccountCard from './SnowflakeAccountCard';
import Button from './Button';
import useStoredAccounts from '../hooks/useStoredAccounts';

function SnowflakeAccountsViewer() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectPersistedAccountViewerOpen);
  const { accounts, addAccount, updateAccount, removeAccount } = useStoredAccounts();
  const [showNewAccountCard, setShowNewAccountCard] = useState<boolean>(false);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setPersistedAccountViewerOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white p-6 shadow-xl">
                    <div>
                      <div className="flex items-start justify-between">
                        <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                          Snowflake accounts
                        </DialogTitle>
                        <div className="ml-[auto]">
                          <Button
                            onClick={() => {
                              setShowNewAccountCard(true);
                            }}
                          >
                            Add account
                          </Button>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={() => dispatch(setPersistedAccountViewerOpen(false))}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="text-sm text-gray-500">Add, edit and remove snowflake accounts</div>
                    <div className="relative my-4 p-4 rounded-lg border-[1px] border-gray-200 bg-gray-100 flex-1 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 wide:grid-cols-3 auto-rows-[12rem]">
                      {accounts.map((account) => (
                        <SnowflakeAccountCard
                          key={account.accountLocator}
                          accountLocator={account.accountLocator}
                          username={account.username}
                          addAccount={addAccount}
                          updateAccount={updateAccount}
                          removeAccount={removeAccount}
                          isNewAccount={false}
                        />
                      ))}
                      {showNewAccountCard && (
                        <SnowflakeAccountCard
                          accountLocator=""
                          username=""
                          isNewAccount={true}
                          addAccount={(accountData) => {
                            addAccount(accountData);
                            setShowNewAccountCard(false);
                          }}
                          updateAccount={updateAccount}
                          removeAccount={removeAccount}
                          onCancel={() => {
                            setShowNewAccountCard(false);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default SnowflakeAccountsViewer;

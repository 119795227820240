import React, { useRef, useState } from 'react';
import Button from './Button';
import { getEnvVariableValue } from '../utilities/parsers';
import type { IDeployment } from '../reducers/deploymentsSlice';

interface IProjectInstanceCardDetailsProps {
  customerName: string;
  customerLogoUrl: string;
  projectInstance: IDeployment;
}

export default function ProjectInstanceCardDetails({
  customerName,
  customerLogoUrl,
  projectInstance,
}: IProjectInstanceCardDetailsProps) {
  const variables = [
    {
      name: 'Account locator',
      value: getEnvVariableValue(projectInstance.ciVariables, 'DATAOPS_SOLE_ACCOUNT'),
      secret: false,
    },
    {
      name: 'Account username',
      value: getEnvVariableValue(projectInstance.ciVariables, 'DATAOPS_SOLE_USERNAME'),
      secret: false,
    },
    {
      name: 'Account password',
      value: getEnvVariableValue(projectInstance.ciVariables, 'DATAOPS_SOLE_PASSWORD'),
      secret: true,
    },
  ];

  const ProjectDetails = variables.map(({ name, value, secret }, index) => {
    const [hidden, setHidden] = useState(secret);
    const textInputRef = useRef(null);
    const [isCopied, setIsCopied] = useState(false);

    const toggleHidden = () => {
      setHidden(!hidden);
    };
    async function copyTextToClipboard(text: string) {
      if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(text);
      } else {
        document.execCommand('copy', true, text);
      }
    }
    const handleCopyClick = (copyText: string) => {
      copyTextToClipboard(copyText)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((error) => {
          console.log('Error while handling copy click', error);
        });
    };
    return (
      <dl key={index} className="sm:col-span-1">
        {projectInstance.requestExtraStatus !== 'empty' ? (
          <>
            <dt className="text-xs font-medium text-gray-500">{name}</dt>
            <dd className="ml-2 flex">
              <input
                type={hidden ? 'password' : 'text'}
                value={value}
                ref={textInputRef}
                readOnly
                className={'font-mono w-full'}
              />
              {secret && (
                <Button
                  onClick={() => {
                    toggleHidden();
                  }}
                  intent="light"
                  size="small"
                >
                  {hidden ? 'Reveal' : 'Conceal'}
                </Button>
              )}
              <Button
                onClick={() => {
                  handleCopyClick(value);
                }}
                intent="light"
                size="small"
                className={isCopied ? 'bg-lime-300 hover:bg-lime-200' : 'bg-white'}
              >
                Copy
              </Button>
            </dd>
          </>
        ) : (
          <div className="w-full h-10 bg-gray-100 rounded-lg bg-shine"></div>
        )}
      </dl>
    );
  });

  return (
    <div className="px-4 py-5 sm:p-6">
      <dt className="mb-2 flex flex-row flex-wrap content-center items-center text-base font-normal text-gray-900">
        {customerLogoUrl !== '' && <img className="h-6 max-w-full rounded-full mr-2" src={customerLogoUrl} alt="" />}
        <span className="text-sm font-medium text-gray-500">{customerName} Details</span>
      </dt>
      <dd className="grid grid-cols-1 gap-x-4 gap-y-1 sm:grid-cols-1">{ProjectDetails}</dd>
    </div>
  );
}

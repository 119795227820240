import React from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';

interface IErrorMessagesProps {
  errors: string[];
  listMessage?: string;
}

export default function ErrorMessages({ errors, listMessage }: IErrorMessagesProps) {
  const firstMessage = errors[0];
  const message = listMessage != null ? listMessage : firstMessage;
  return (
    <div className="m-4 p-4 rounded-md bg-red-50 ">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3 break-words w-5/6">
          <h3 className="text-sm font-medium text-red-900">{message}</h3>
          <div className="mt-2 text-sm text-red-800">
            <ul role="list" className="list-disc space-y-1 pl-5">
              {errors.map((error, index) => {
                if (index === 0) {
                  return null;
                }
                return <li key={index}>{error}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

import type { IEnvVariable } from '../interfaces/IEnvVariable';

export function parseGuid(guid: string) {
  // Example: gid://gitlab/Project/7736
  const parts = guid !== '' ? guid.split('/') : [guid];
  return parts[parts.length - 1];
}

export function extractProjectPath(fullPath: string) {
  // Example: /snowflake/frostbyte-poc/luke.crumley/tasty-bytes-verizon/-/pipelines/1321397
  const pathParts = fullPath !== '' ? fullPath.split('/-/') : [fullPath];
  return pathParts[0];
}

export function getEnvVariableValue(envVariables: IEnvVariable[], name: string) {
  const envVariable = envVariables.find((envVariable) => envVariable.key === name);
  return envVariable !== undefined ? envVariable.value : '';
}

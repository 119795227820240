import React, { useRef } from 'react';
import {
  CheckCircleIcon,
  XCircleIcon,
  RocketLaunchIcon,
  ArrowDownOnSquareIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import UpdateModal from './UpdateModal';
import { useMainPipelineStatus } from '../hooks/usePipelineStatus';
import { getMainPipeline } from '../utilities/pipelines';
import Button from './Button';
import { getEnvVariableValue } from '../utilities/parsers';
import { getConfig } from '../config/config-helper';
import { useAppSelector } from '../hooks';
import { selectIsReadOnly, type IDeployment } from '../reducers/deploymentsSlice';

export type IPipelineStatus = 'SUCCESS' | 'FAILED' | 'PENDING';

interface IProjectStatusProps {
  projectInstance: IDeployment;
  refreshProject: (projectId: string) => void;
}

interface IUpdateRequiredProps {
  projectId: string;
  updateUrl: string;
  projectInstance: IDeployment;
  updateProject: (projectId: string) => void;
}

const UpdateRequired: React.FC<IUpdateRequiredProps> = ({ projectId, updateUrl, projectInstance, updateProject }) => {
  const [open, setOpen] = React.useState(false);
  const cancelButtonRef = useRef(null);
  const projectAccountName = getEnvVariableValue(projectInstance.ciVariables, 'DATAOPS_SOLE_ACCOUNT');
  const projectUsername = getEnvVariableValue(projectInstance.ciVariables, 'DATAOPS_SOLE_USERNAME');
  const projectPassword = getEnvVariableValue(projectInstance.ciVariables, 'DATAOPS_SOLE_PASSWORD');
  const isReadOnlyUser = useAppSelector(selectIsReadOnly);
  const commitsBehind = projectInstance.commitsBehind !== undefined ? projectInstance.commitsBehind : [];

  return (
    <div className="flex flex-col justify-center">
      {commitsBehind.length === 0 ? (
        <div className="text-gray-600 text-sm">Solution up-to-date</div>
      ) : (
        <>
          <UpdateModal
            projectId={projectId}
            updateUrl={updateUrl}
            refreshProject={updateProject}
            cancelButtonRef={cancelButtonRef}
            open={open}
            projectInstanceId={projectInstance.id}
            projectInstanceName={projectInstance.name}
            projectAccountName={projectAccountName}
            projectUsername={projectUsername}
            prefillProjectPassword={projectPassword}
            setOpen={setOpen}
          />
          <div className="flex flex-row mb-1">
            <ExclamationTriangleIcon className="w-5 h-5 mr-1 mb-1 text-yellow-500" />
            <div className="text-yellow-600 text-sm text-center ">Ready (but behind the times)</div>
          </div>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            intent="secondary"
            className="w-full hover:bg-dataops-light-blue hover:text-white"
            disabled={isReadOnlyUser}
          >
            <ArrowDownOnSquareIcon className="w-5 h-5 mr-1" />
            Solution update available
          </Button>
        </>
      )}
    </div>
  );
};

const ProjectStatus: React.FC<IProjectStatusProps> = (props) => {
  const mainPipelineStatus = useMainPipelineStatus(props.projectInstance);
  const pipelines = props.projectInstance.pipelines;
  const mainPipeline = getMainPipeline(pipelines);
  const { dataopsCatalogApiUpdateEndpoint, dataopsliveBaseUrl } = getConfig();
  const commitsBehind = props.projectInstance.commitsBehind !== undefined ? props.projectInstance.commitsBehind : [];

  const totalJobs = mainPipeline?.jobs.length ?? 0;
  const successfulJobs = mainPipeline?.jobs.filter((job) => job.status === 'SUCCESS').length ?? 0;
  const percent = Math.floor((successfulJobs / totalJobs) * 100);

  return (
    <div className="flex-grow flex flex-col justify-center">
      {mainPipelineStatus === 'SETUP_SUCCESS' && (
        <>
          {commitsBehind.length === 0 ? (
            <div className="flex flex-row">
              <CheckCircleIcon className="w-5 h-5 mr-1 text-lime-600" />
              <div className="text-lime-600 text-sm">Ready</div>
            </div>
          ) : (
            <>
              <UpdateRequired
                projectInstance={props.projectInstance}
                projectId={props.projectInstance.id}
                updateUrl={dataopsCatalogApiUpdateEndpoint}
                updateProject={props.refreshProject}
              />
            </>
          )}
        </>
      )}
      {mainPipelineStatus === 'SETUP_FAILED' && (
        <>
          <div className="flex flex-row">
            <XCircleIcon className="w-5 h-5 mr-1 text-dataops-dark-red" />
            <div className="text-dataops-dark-red text-sm">Setup failed</div>
          </div>
          {mainPipeline !== undefined && (
            <div className="text-sm hover:font-bold">
              <a
                href={`${dataopsliveBaseUrl}${mainPipeline.path}`}
                target="_blank"
                rel="noreferrer"
                className="font-semibold text-dataops-dark-red hover:text-hover-dark-red hover:underline"
              >
                Failed pipeline
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          )}
          <UpdateRequired
            projectInstance={props.projectInstance}
            projectId={props.projectInstance.id}
            updateUrl={dataopsCatalogApiUpdateEndpoint}
            updateProject={props.refreshProject}
          />
        </>
      )}
      {(mainPipelineStatus === 'SETUP_IN_PROGRESS' || mainPipelineStatus === 'ANALYZING_STATUS') && (
        <>
          <div className="flex flex-row">
            <RocketLaunchIcon className="w-5 h-5 mr-1 text-sky-600" />
            <div className="text-sky-600 text-sm">Setup in progress ({percent}%)</div>
          </div>

          <UpdateRequired
            projectInstance={props.projectInstance}
            projectId={props.projectInstance.id}
            updateUrl={dataopsCatalogApiUpdateEndpoint}
            updateProject={props.refreshProject}
          />
        </>
      )}
    </div>
  );
};

export default ProjectStatus;

import React from 'react';
import { classNames } from '../utilities/styleUtils';

export interface IReleaseStatusBadgeProps {
  label: string;
}

const ReleaseStatusBadge: React.FC<IReleaseStatusBadgeProps> = (props) => {
  return (
    <div
      className={classNames(
        props.label === 'Published' || props.label === 'Certified'
          ? 'bg-[#d6eed7] text-dataops-dark-green'
          : 'bg-[#ded6ef] text-dataops-purple',
        'flex h-6 items-center rounded-md px-2.5 py-0.5 text-sm font-medium',
      )}
    >
      {props.label}
    </div>
  );
};

export default ReleaseStatusBadge;

import React, { useState } from 'react';
import Button from './Button';

interface ISnowflakeAccountCardProps {
  accountLocator: string;
  username: string;
  isNewAccount: boolean;
  addAccount: (account: { accountLocator: string; username: string }) => void;
  updateAccount: (
    previousAccount: { accountLocator: string; username: string },
    updatedAccount: { accountLocator: string; username: string },
  ) => void;
  removeAccount: (account: { accountLocator: string; username: string }) => void;
  onCancel?: () => void;
}

function SnowflakeAccountCard(props: ISnowflakeAccountCardProps) {
  const [accountLocator, setAccountLocator] = useState<string>(props.accountLocator);
  const [username, setUsername] = useState<string>(props.username);
  const [isEdited, setIsEdited] = useState<boolean>(props.isNewAccount);

  const onSave = () => {
    if (props.isNewAccount) {
      props.addAccount({ accountLocator, username });
    } else {
      props.updateAccount(
        { accountLocator: props.accountLocator, username: props.username },
        { accountLocator, username },
      );
    }
    setIsEdited(true);
  };

  return (
    <div className="h-fit bg-white p-4 rounded-md border-gray-200 space-y-4">
      <form onSubmit={onSave}>
        <div>
          <label className="flex items-center text-sm font-medium leading-6 text-gray-900">
            Snowflake account locator
          </label>
          <div className="my-1">
            <input
              value={accountLocator}
              disabled={!isEdited}
              required
              onChange={(e) => {
                setAccountLocator(e.target.value);
              }}
              className="block w-full rounded-md border-0 py-1 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label className="flex items-center text-sm font-medium leading-6 text-gray-900">Username</label>
          <div className="my-1">
            <input
              value={username}
              disabled={!isEdited}
              required
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              className="block w-full rounded-md border-0 py-1 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="flex justify-around mt-2">
          {!isEdited && (
            <>
              <Button
                intent="danger"
                className="w-16 justify-center"
                onClick={() => {
                  props.removeAccount({ accountLocator, username });
                }}
              >
                Delete
              </Button>
              <Button
                className="w-16 justify-center"
                onClick={() => {
                  setIsEdited(true);
                }}
              >
                Edit
              </Button>
            </>
          )}
          {isEdited && (
            <>
              <Button
                intent="subtle"
                onClick={() => {
                  props.onCancel !== undefined ? props.onCancel() : setIsEdited(false);
                }}
              >
                Cancel
              </Button>
              <Button type="submit" className="w-16 justify-center" onClick={onSave}>
                Save
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  );
}

export default SnowflakeAccountCard;

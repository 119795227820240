import React, { useEffect, useRef, useState } from 'react';
import {
  AcademicCapIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { useValidationPipelineStatus } from '../hooks/usePipelineStatus';
import { getValidationPipeline } from '../utilities/pipelines';
import type { IValidationPipelineStatus } from '../interfaces/IValidationPipelineStatus';
import ValidationModal from './ValidationModal';

import Button from './Button';
import { getEnvVariableValue } from '../utilities/parsers';
import { useAppSelector } from '../hooks';
import { getConfig } from '../config/config-helper';
import { selectIsReadOnly, type IDeployment, type IPipeline } from '../reducers/deploymentsSlice';

interface IStatusProps {
  validationStatus: IValidationPipelineStatus;
  validPipeline: IPipeline | undefined;
}

interface IValidationStatusProps {
  projectInstance: IDeployment;
  refreshProject: (deploymentId: string) => void;
}

const Status: React.FC<IStatusProps> = ({ validationStatus, validPipeline }: IStatusProps) => {
  const { dataopsliveBaseUrl } = getConfig();
  return (
    <div className="mb-2">
      {validationStatus === 'ANALYZING_STATUS' && (
        <div className="flex flex-row">
          <QuestionMarkCircleIcon className="w-5 h-5 mr-1 text-gray-600" />
          <div className="text-gray-600 text-sm">Updating status</div>
        </div>
      )}
      {validationStatus === 'MISSING_PIPELINE' && (
        <div className="flex flex-row">
          <ExclamationTriangleIcon className="w-5 h-5 mr-1 text-gray-600" />
          <div className="text-gray-600 text-sm">Validation job missing</div>
        </div>
      )}
      {validationStatus === 'VALIDATION_FAILED' && (
        <div className="flex flex-col">
          <div className="flex flex-row">
            <XCircleIcon className="w-5 h-5 mr-1 text-dataops-dark-red" />
            <div className="text-dataops-dark-red text-sm">Validation failed</div>
          </div>
          {validPipeline !== undefined && (
            <div>
              <a
                href={`${dataopsliveBaseUrl}${validPipeline.path}`}
                target="_blank"
                rel="noreferrer"
                className="font-semibold text-dataops-dark-red hover:text-hover-dark-red hover:underline"
              >
                Failed pipeline
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          )}
        </div>
      )}
      {validationStatus === 'VALIDATION_IN_PROGRESS' && (
        <div className="flex flex-row">
          <RocketLaunchIcon className="w-5 h-5 mr-1 text-sky-600" />
          <div className="text-sky-600 text-sm">Validation in progress</div>
        </div>
      )}
      {validationStatus === 'VALIDATION_SUCCESS' && (
        <div className="flex flex-row">
          <CheckCircleIcon className="w-5 h-5 mr-1 text-lime-600" />
          <div className="text-lime-600 text-sm">Passed</div>
        </div>
      )}
    </div>
  );
};

const ValidationStatus: React.FC<IValidationStatusProps> = ({ projectInstance, refreshProject }) => {
  const [validateAvailable, setValidateAvailable] = useState(false);
  const validationStatus = useValidationPipelineStatus(projectInstance);
  const pipelines = projectInstance.pipelines;
  const validPipeline = getValidationPipeline(pipelines);
  const projectAccountName = getEnvVariableValue(projectInstance.ciVariables, 'DATAOPS_SOLE_ACCOUNT');
  const projectUsername = getEnvVariableValue(projectInstance.ciVariables, 'DATAOPS_SOLE_USERNAME');
  const projectPassword = getEnvVariableValue(projectInstance.ciVariables, 'DATAOPS_SOLE_PASSWORD');
  const isReadOnlyUser = useAppSelector(selectIsReadOnly);

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (validationStatus === 'VALIDATION_SUCCESS' || validationStatus === 'VALIDATION_FAILED') {
      setValidateAvailable(true);
    } else {
      setValidateAvailable(false);
    }
  }, [validationStatus]);

  return (
    <div className="flex-grow flex flex-col justify-center">
      <Status validationStatus={validationStatus} validPipeline={validPipeline} />
      <div className="w-full border-1 text-gray-500 group flex relative">
        {validateAvailable && (
          <ValidationModal
            open={open}
            setOpen={setOpen}
            cancelButtonRef={cancelButtonRef}
            projectInstanceId={projectInstance.id}
            projectInstanceName={projectInstance.name}
            projectId={projectInstance.guid}
            projectAccountName={projectAccountName}
            projectUsername={projectUsername}
            prefillProjectPassword={projectPassword}
            refreshProject={refreshProject}
          />
        )}
        <Button
          type="button"
          disabled={!validateAvailable || isReadOnlyUser}
          onClick={() => {
            setOpen(true);
          }}
          intent="light"
          className="w-full hover:!bg-dataops-light-blue hover:!text-white"
        >
          <AcademicCapIcon className="w-5 h-5 mr-1" />
          Validate solution
        </Button>
      </div>
    </div>
  );
};

export default ValidationStatus;

export function opportunityIdLinkConverter(userInput: string): string {
  // Salesforce Opportunity ID validation
  const pattern = /https:\/\/snowforce\.lightning\.force\.com\/lightning\/r\/Opportunity\/([a-zA-Z0-9]+)\/view/;
  const match = pattern.exec(userInput);
  if (match !== null) {
    return match[1]; // return the opportunity ID
  }
  return userInput;
}
export function opportunityIdValidation(opportunityId: string): string {
  // Salesforce Opportunity ID validation
  const pattern = /[ %&?#]/;
  if (opportunityId.startsWith('http')) {
    return 'Not a url we recognize, try pasting in a Salesforce URL or just the ID.';
    // } else if (opportunityId.length === 0) {
    //   return 'Opportunity ID required to demo to customer / prospect.';
  } else if (opportunityId.length > 18) {
    return 'ID too long, please check the ID in Salesforce.';
  } else if (opportunityId.length < 18) {
    return 'ID too short, please check the ID in Salesforce.';
  } else if (pattern.exec(opportunityId) !== null) {
    return 'Spaces and special chars are unexpected, try pasting in a Salesforce URL or just the ID.';
  }
  return '';
}

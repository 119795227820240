import React from 'react';

function SearchBar({ getAutocomplete, getInputProps, getButtonProps }: any) {
  return (
    <>
      <div className="sui-search-box__wrapper focus:ring-sky-600">
        <input
          style={{ padding: '0.5rem', border: '1px solid #ccc' }}
          {...getInputProps({
            placeholder: 'Search all solutions',
          })}
          className="w-full p-[0.5rem] rounded-md text-[14px] focus:ring-sky-600 focus-visible:outline-dataops-light-blue"
          data-testid="search-input"
        />
        {getAutocomplete()}
      </div>
      <input
        {...getButtonProps()}
        className="ml-2 p-[0.5rem] rounded-md bg-button-background text-button-text hover:bg-button-hover-background hover:text-button-hover-text"
        data-testid="search-button"
      />
    </>
  );
}

export default SearchBar;

import React from 'react';

interface IHolSetupProps {
  value: number;
  setValue: (value: number) => void;
}

export default function HolSetup(props: IHolSetupProps) {
  return (
    <div>
      <label className="flex items-center text-sm font-medium leading-6 text-gray-900">Hands-on lab</label>
      <div className="text-sm text-gray-500">Select the number of participants</div>
      <div className="flex mt-1">
        <input
          type="range"
          min="0"
          max="5"
          step="1"
          value={props.value}
          onChange={(e) => {
            props.setValue(parseInt(e.target.value));
          }}
        />
        <div className="text-sm text-gray-500 ml-4">{props.value} participants</div>
      </div>
    </div>
  );
}

import React, { type ReactNode } from 'react';
import InformationTooltip from './InformationTooltip';

interface IFormFieldProps {
  label: string;
  labelClassName?: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  type?: string;
  className?: string;
  informationTooltip?: string;
  children?: ReactNode;
  labelExtra?: ReactNode;
  testId?: string;
}

export default function FormField(props: IFormFieldProps) {
  return (
    <div className={props.className ?? ''}>
      <div className="flex items-center">
        <label
          className={`flex items-center text-sm font-medium leading-6 text-gray-900 ${props.labelClassName ?? ''}`}
        >
          {props.label}
          {props.informationTooltip !== undefined && <InformationTooltip tooltip={props.informationTooltip} />}
        </label>
        {props.labelExtra}
      </div>
      <div className="my-2">
        <input
          type={props.type ?? ''}
          value={props.value}
          disabled={props.disabled}
          required={props.required}
          placeholder={props.placeholder ?? ''}
          onChange={props.onChange}
          className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6 disabled:bg-gray-200 disabled:ring-0 disabled:text-black"
          data-testid={props.testId}
        />
      </div>
      {props.children}
    </div>
  );
}

import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import ProjectDeploymentCard from './ProjectDeploymentCard';
import CloseButton from './CloseButton';
import type { IDeployment } from '../reducers/deploymentsSlice';
import Pagination, { type IPageState } from './Pagination';
import FilterInput from './FilterInput';

interface IProjectsByTemplate {
  templateId: string;
  templateName: string;
  projects: IDeployment[];
}

interface ProjectDeploymentsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  projects: IDeployment[];
  refreshProjects: () => void;
}

export default function ProjectDeploymentsModal({
  open,
  setOpen,
  projects,
  refreshProjects,
}: ProjectDeploymentsModalProps) {
  const [totalDeployments, setTotalDeployments] = useState<number>(projects.length);
  const [filter, setFilter] = useState<string>('');
  const [pageState, setPageState] = useState<IPageState>({
    currentPage: 1,
    itemsPerPage: 5,
  });
  const startIndex = (pageState.currentPage - 1) * pageState.itemsPerPage;
  const endIndex = startIndex + pageState.itemsPerPage;
  const deploymentsGroupedByTemplate: IProjectsByTemplate[] = projects
    .filter((deployment) => deployment.name.toLowerCase().includes(filter.toLowerCase()))
    .slice(startIndex, endIndex)
    .reduce((acc: IProjectsByTemplate[], curr: IDeployment) => {
      const templateId = curr.ciVariables.find((v) => v.key === 'DATAOPS_CATALOG_SOLUTION_TEMPLATE_ID');
      const templateName = curr.ciVariables.find((v) => v.key === 'DATAOPS_CATALOG_SOLUTION_TEMPLATE_NAME');
      if (templateId !== undefined && templateName !== undefined) {
        const matchingTemplate = acc.find((m) => m.templateId === templateId.value);
        if (matchingTemplate !== undefined) {
          matchingTemplate.projects.push(curr);
        } else {
          acc.push({ templateId: templateId.value, templateName: templateName.value, projects: [curr] });
        }
      }
      return acc;
    }, []);

  useEffect(() => {
    // Count the total number of deployments filtered.
    if (filter !== '') {
      setTotalDeployments(
        projects.filter((deployment) => deployment.name.toLowerCase().includes(filter.toLowerCase())).length,
      );
    }
    setPageState({ ...pageState, currentPage: 1 });
  }, [filter]);

  useEffect(() => {
    // Ensure the total number of deployments is updated when the projects change.
    setTotalDeployments(projects.length);
  }, [projects.length]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30 " onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg divide-y-2 bg-gray-50 text-left shadow-xl transition-all sm:my-24 sm:w-full sm:max-w-7xl">
                <div>
                  <div className="flex justify-between border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Deployments</h3>
                    <CloseButton
                      setOpen={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                </div>
                {deploymentsGroupedByTemplate.length === 0 && (
                  <div>
                    <div className="p-6 text-xl font-semibold leading-6 text-gray-900">No deployments yet</div>
                    <div className="p-6 text-base leading-6 text-gray-900">
                      Create a deployment by setting up a solution from the search results.
                    </div>
                  </div>
                )}
                <FilterInput filter={filter} setFilter={setFilter} />
                {deploymentsGroupedByTemplate.map((projectGroup) => {
                  return (
                    <div key={`${projectGroup.templateName}-${projectGroup.templateId}`}>
                      <h4 className="p-6 text-2xl font-semibold leading-6 text-gray-900">
                        {projectGroup.templateName}
                      </h4>
                      <div>
                        {projectGroup.projects.map((project) => {
                          return (
                            <ProjectDeploymentCard
                              key={project.guid}
                              projectInstance={project}
                              refreshProjects={refreshProjects}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                <Pagination
                  currentPage={pageState.currentPage}
                  itemsPerPage={pageState.itemsPerPage}
                  onPageChange={(pageNumber: number) => {
                    setPageState({ ...pageState, currentPage: pageNumber });
                  }}
                  totalItems={totalDeployments}
                />
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
